import React, { useState } from 'react';
import { Container, Typography, Paper, Box, TextField, Button, CssBaseline, AppBar, Toolbar, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AxiosClient from 'src/clients/axios.client';
import { enqueueSnackbar } from 'notistack';
import BackgroundImage from "src/assets/images/bg_image.jpg";


const GetInTouch = () => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        console.log('Form submitted:', { name, email, message });
        if (!name || !email || !message) {
            enqueueSnackbar('Preencha todos os campo!', { variant: `warning` })
        }
        setLoading(true);
        try {
            const body = {
                name,
                email,
                message
            }
            await AxiosClient.getInTouch(body)
            enqueueSnackbar('Mensagem enviada com sucesso!', { variant: 'success' })
            setName('');
            setEmail('');
            setMessage('');

        } catch (error) {
            enqueueSnackbar('Falha ao enviar mensagem!', { variant: 'error' })
        } finally {
            setLoading(false);
        }
    };

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <Box
            style={{
                minHeight: "100vh",
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto",
            }}
        >
            <CssBaseline />
            <AppBar position="static">
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" color="inherit" noWrap>
                        Evento Sem Fila - Contato
                    </Typography>
                    <Button color="inherit" onClick={handleBackToLogin}>
                        Voltar ao Login
                    </Button>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="md" style={{ marginTop: '2rem' }}>
                <Paper elevation={3} style={{ padding: '2rem' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Entre em Contato Conosco
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Box mb={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Nome"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                required
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="E-mail"
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </Box>
                        <Box mb={2}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Mensagem"
                                multiline
                                rows={4}
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                required
                            />
                        </Box>
                        <Box mb={2}>
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                {loading ? <CircularProgress size={24} /> : 'Enviar'}
                            </Button>
                        </Box>
                    </form>
                </Paper>
            </Container>
        </Box>
    );
};

export default GetInTouch;
