import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Modal,
  Stack,
  CircularProgress,
  TextField,
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './AddCoordinatorModal.css'

import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'
import AxiosClient from 'src/clients/axios.client'
import { EventModel } from 'src/data/models'
import Button from 'src/components/Button'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: (data: any) => void
  eventId: string
}

const AddCoordinatorModal: React.FC<Props> = ({ eventId, showModal, setShowModal, submit }) => {
  const [loading, setLoading] = useState(true)
  const [coordinators, setCoordinators] = useState('')
  const [, setEvent] = useState<EventModel>({
    agenda: [""],
    budget: 0,
    userId: "",
    cancellationReason: "",
    createdAt: "",
    description: "",
    internalNotes: "",
    dressCode: "",
    endAt: new Date(),
    startAt: new Date(),
    eventType: "",
    id: "",
    isCancelled: false,
    isRSVPRequired: true,
    isTicketed: false,
    location: "",
    registrationDeadline: new Date(),
    speakers: [""],
    sponsors: [""],
    theme: "",
    maxTicketQuantity: 0,
    title: "",
    updatedAt: "",
    maxAgeForFreeAdmission: 0,
    isPrivate: false,
    ticketsCreated: 0,
  })

  const getEvent = useCallback(async () => {
    await AxiosClient.getEventById({ eventId, setLoading, setEvent })
  }, [eventId])

  useEffect(() => {
    getEvent()
  }, [getEvent])

  const handleCoordinators = (val: string) => setCoordinators(val)
  const handleClose = (): void => { setShowModal(false) }
  const handleSubmit = (): void => {
    const resultArray = coordinators.split(/[, \n]+/);
    const filteredArray = resultArray.filter(str => str.trim() !== '');
    submit(filteredArray)
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Adicionar Coordenador ao Evento</Header>
        </Box>
        {loading ?
          <Box sx={styles.loaderContainer}>
            <CircularProgress color="secondary" sx={styles.loading} />
          </Box> :
          <Box sx={styles.innerContainer}>
            <Typography sx={styles.note}>Aviso:</Typography>
            <Typography sx={styles.note}>Novos coordenadores receberão um convite por e-mail com um link para
              definir a senha da sua conta.
            </Typography>
            <Typography sx={styles.description}>Digite o endereço de e-mail dos coordenadores</Typography>
            <Typography sx={styles.info}>{`(Use espaço, vírgula ou Enter para adicionar vários endereços de e-mail. Máximo de 10 endereços de e-mail permitidos de cada vez.)`}</Typography>
            <TextField
              sx={styles.multilineTextField}
              id="outlined-multiline-static"
              label="Endereços de email"
              multiline
              rows={5}
              placeholder="Entre com os endereços de email"
              onChange={(
                event: ChangeEvent<HTMLInputElement>
              ) => {
                handleCoordinators(event.target.value)
              }}
              inputProps={{
                maxLength: 70
              }}
            />
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              sx={{ paddingTop: 5 }}
            >
              <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
              <Button onClick={handleSubmit} variant="contained">Salvar</Button>
            </Stack>
          </Box>
        }
      </Box>
    </Modal>
  )
}

export default AddCoordinatorModal
