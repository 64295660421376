import theme from "src/themes/theme";

const styles = {
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 4
    },
    loading: {
        alignText: 'center'
    },
    table: {
        width: '100%',
        maxWidth: '100%',
    },
    tableFooter: {
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    tablePagination: {
        borderBottom: 'none',
    },
    speedDial: {
        '& .MuiFab-primary': {
            alignSelf: 'center',
            '& .MuiSpeedDialIcon-icon': {
                fontSize: 18,
                color: 'white',
                alignSelf: 'center',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 20,
                padding: 1,
            }
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2),
        },
    },
    textField: {
        width: '250px',
        marginLeft: '10px',
        '& label.Mui-focused': {
            color: theme.palette.secondary.main
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px',
            // backgroundColor: theme.palette.grey[200],
            '&.Mui-focused fieldset': {
                borderRadius: '2px',
                borderColor: theme.palette.secondary.main,
            }
        }
    },
    header: {
        color: theme.palette.grey[700],
        fontSize: 25,
        fontWeight: '600'
    },
    eventTile: {
        fontSize: 25,
        fontWeight: '500',
        paddingLeft: 2,
        color: theme.palette.primary.main,
        alignSelf: 'center',
    },
}
export default styles;