import { useCallback, useEffect, useState, } from "react";
import { enqueueSnackbar } from 'notistack';
import { Box } from "@mui/material";
import CoordinatorWithOutAccount from './components/CoordinatorWithOutAccount';
import CoordinatorWithAccount from './components/CoordinatorWithAccount';
import { useLocation, useNavigate } from "react-router-dom";
import AxiosClient from "src/clients/axios.client";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const CoordinatorConfirmation = () => {
    const navigate = useNavigate();
    const query = useQuery();
    const id = query.get('id');
    const temp = query.get('temp') === 'true';

    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = useCallback(async () => {
        try {
            if (temp && password !== confirmPassword) {
                setError('As senhas não coincidem');
                return;
            }
            if (temp && !username) {
                setError('Nome de usuário vazio');
                return;
            }
            if (temp && !username && !password) {
                setError('Nome/Password devem ser preenchidos.')
                return
            }
            if (!id) {
                setError('Token inválido')
                return
            }
            const params = {
                coordinatorId: id,
                password: password || undefined,
                name: username || undefined,
            }
            setLoading(true)
            const isConfirmed = await AxiosClient.confirmCoordinatorInvite(params)
            setLoading(false)
            if (isConfirmed.status === 200) {
                enqueueSnackbar('Sucesso', { variant: 'success' })
                navigate('/login');
            } else {
                enqueueSnackbar('Falha', { variant: 'error' })
            }

        } catch (error) {
            console.log('Error:: ', error)
        }
    }, [confirmPassword, id, navigate, password, temp, username])

    useEffect(() => {
        if (!id) {
            navigate('/login');
        }
        if (id && !temp) {
            handleSubmit();
        }
    }, [temp, id]);

    return (
        <Box>
            {temp ? <CoordinatorWithOutAccount
                handleConfirm={handleSubmit}
                password={password}
                setPassword={setPassword}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                username={username}
                setUsername={setUsername}
                setError={setError}
                error={error}
            /> : <CoordinatorWithAccount loading={loading} error={error} />}
        </Box>
    );
};

export default CoordinatorConfirmation;
