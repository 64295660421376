import theme from 'src/themes/theme'

const styles = {
  viewModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: 500,
    minHeight: '50%',
    // p: 4,
  },
  innerContainer: {
    height: 290,
    overflow: 'scroll'
    // p: 4
  },
  headerContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    p: '10px',
    paddingLeft: 4
  },
  row: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    alignText: 'center'
  },
  addSponsorTxt: {
    fontSize: 10,
    marginLeft: 1,
    alignVertical: 'baseline',
    textTransform: 'uppercase'
  },
  buttonContainer: {
    bottom: 20,
    right: 20,
  }
}
export default styles
