import theme from 'src/themes/theme'

export const styles = {
    textField: {
        width: 400,
        '& label.Mui-focused': {
            color: theme.palette.secondary.main
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '2px',
            backgroundColor: theme.palette.grey[200],
            '&.Mui-focused fieldset': {
                borderRadius: '2px',
                borderColor: theme.palette.secondary.main
            }
        }
    },
    row: {
        display: 'flex',
        direction: 'row',
        justifyContent: 'flex-start'
    },

    title: {
        marginBottom: 5,
        fontWeight: '500',
    },
    submit: {
        padding: '10px 0',
        width: 200,
    },
    editUserButton: {
        padding: '10px 0',
        width: 200,
        marginTop: 10,
        right: 0,
        alignSelf: 'end'
    },
    editIcon: {
        // color: '#fff',
        // fontSize: 20,
        padding: 1,

    },
    editAccount: {
        position: 'absolute',
        right: 0,
        zIndex: 1,
    },
    container: {
        position: 'relative'
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    avatarContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center', height: '100%', alignItems: 'center',
        borderRadius: '2px',
        backgroundColor: theme.palette.grey[100],
    },
    error: {
        fontSize: 15,
        color: theme.palette.error.main,
        marginBottom: 1,
    }
}