import api from "src/config/api"
import { CancelTicketType, ChangePasswordType, ConfirmCoordType, ConfirmTicketSubmissionType, ConfirmUserType, CreateAttendee, CreateCoordType, CreateEvents, CreateOwnTicket, CreateTicket, CreateTicketFoAllAttendees, CreateTicketType, DownloadAttendeeList, DownloadTicketType, GetAttendee, GetAttendeeByCode, GetAttendeeList, GetEvent, GetEventCoordinators, GetEvents, GetTempCoordinators, GetTicketOptions, GetTicketsType, GetUserType, ListEventsByCoordinatorType, LoginType, RecoveryPasswordType, RemoveCoordType, RemoveTempCoordType, SignUpType, UpdateAttendee, UpdateEvent, UpdateEventSchedule, UpdateTicketTypeType, UpdateUserType } from "./types"
import { EventModel, EventWeddingModel } from "src/data/models"

const AxiosClient = {
    async getEvents(params: GetEvents) {
        return await api.get('/api/event/list', { params })
    },

    async listEventsByCoordinator(data: ListEventsByCoordinatorType) {
        const { coordId, ...params } = data
        return await api.get(`/api/event/list-by-coordinator/${coordId}`, { params })
    },

    async createEvent(data: CreateEvents) {
        const httpResponse = await api.post('/api/event', data)
        return httpResponse
    },

    async getEventById({ eventId, setLoading, setEvent }: GetEvent) {
        api
            .get(`/api/event/identification/${eventId}`)
            .then((httpResponse) => {
                const data = httpResponse.data as EventModel
                const { startAt, endAt, registrationDeadline } = data
                setEvent({
                    ...data,
                    startAt: new Date(startAt),
                    endAt: new Date(endAt),
                    registrationDeadline: new Date(registrationDeadline as Date)
                })
                setLoading(false)
            })
            .catch((error) => {
                console.error('Erro ao obter evento:', error)
                setLoading(false)
            })
    },

    async getTicketTypeByCode(tiketTypeCode: string) {
        const httpResponse = await api.get(`/api/ticket-type/${tiketTypeCode}`)
        return httpResponse;
    },

    async updateEvent(data: UpdateEvent | UpdateEventSchedule, eventId: string) {
        const httpResponse = await api.put(`/api/event/${eventId}`, data)
        return httpResponse
    },

    async deleteEvent(eventId: string) {
        const httpResponse = await api.delete(`/api/event/${eventId}`)
        return httpResponse
    },

    async cancelEvent(data: any) {
        const { id, ...params } = data;
        const httpResponse = await api.put(`/api/event/cancel/${id}`, params)
        return httpResponse
    },

    async restoreEvent(id: string) {
        const httpResponse = await api.put(`/api/event/restore/${id}`, { id })
        return httpResponse
    },

    async getEventCoordinators({
        eventId, page, pageSize
    }: GetEventCoordinators) {
        const params = { eventId, page, pageSize }
        return await api.get(`/api/event/coordinators`, { params })
    },

    async getAssociation(coordId: string, eventId: string) {
        return await api.get(`/api/event/${eventId}/coordinator/${coordId}`)
    },

    async getTemporaryCoordinators({
        eventId, page, pageSize
    }: GetTempCoordinators) {
        const params = { eventId, page, pageSize }
        const httpResponse = await api.get(`/api/event/coordinators/temporary`, { params })
        return httpResponse
    },

    async createCoordinator(data: CreateCoordType) {
        const httpResponse = await api.put('/api/event/coordinator/create', data)
        return httpResponse
    },

    async removeCoordinator(data: RemoveCoordType) {
        const httpResponse = await api.put('/api/event/coordinator/remove', data)
        return httpResponse
    },

    async removeTempCoordinator(data: RemoveTempCoordType) {
        const httpResponse = await api.put('/api/event/coordinator/temporary/remove', data)
        return httpResponse
    },

    async confirmCoordinatorInvite(data: ConfirmCoordType) {
        const httpResponse = await api.put('/api/event/coordinator/confirm', data)
        return httpResponse
    },

    async login(data: LoginType) {
        const httpResponse = await api.post('/api/signin', data)
        return httpResponse
    },

    async signup(data: SignUpType) {
        const httpResponse = await api.post('/api/signup', data)
        return httpResponse
    },

    async resetPassword(email: string) {
        const httpResponse = await api.put('/api/reset-password', { email })
        return httpResponse
    },

    async recoveryPassword(data: RecoveryPasswordType) {
        const httpResponse = await api.put('/api/recovery-password', data)
        return httpResponse
    },

    async getAccount(id: string) {
        const httpResponse = await api.get(`/api/user-by-id/${id}`)
        return httpResponse
    },

    async getUser(params: GetUserType) {
        const httpResponse = await api.get('/api/user', { params })
        return httpResponse
    },

    async updateAccount(data: UpdateUserType) {
        const httpResponse = await api.put(`/api/user/${data.id}`, data)
        return httpResponse
    },

    async updateAvatarAccount(id: string, formData: any) {
        const httpResponse = await api.put(`/api/user/avatar/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return httpResponse
    },


    async deleteAvatarAccount(id: string) {
        const httpResponse = await api.put(`/api/user/avatar/${id}/delete`)
        return httpResponse
    },

    async getTickets(params: GetTicketsType) {
        return await api.get('/api/tickets', { params })

    },

    async createAttendee(data: CreateAttendee) {
        const httpResponse = await api.post('/api/attendee', data)
        return httpResponse
    },

    async createAttendees(formData: any) {
        const httpResponse = await api.post(`/api/attendees`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return httpResponse
    },

    async getAttendeeList(params: GetAttendeeList) {
        return api.get('/api/attendees', { params })
    },

    async downloadAttendeeList(params: DownloadAttendeeList) {
        return await api.get('/api/attendee/download', {
            params,
        })
    },

    async getAttendee(params: GetAttendee) {
        return api.get('/api/attendee-by-id', { params })
    },

    async getAttendeeByCode(params: GetAttendeeByCode) {
        return api.get('/api/attendee-by-code', { params })
    },

    async updateAttendee(data: UpdateAttendee) {
        const httpResponse = await api.put(`/api/attendee`, data)
        return httpResponse
    },

    async confirmPresenceAttendee(data: { attendeeCode: string, internalNotes?: string, isChild: boolean }) {
        const httpResponse = await api.put(`/api/attendee/confirm-presence`, data)
        return httpResponse
    },

    async deleteAttendee(id: string) {
        return await api.delete(`/api/attendee/${id}`)
    },


    async getTicketOptionsList(params: GetTicketOptions) {
        return api.get('/api/ticket-type', { params })
    },

    async createTicket(data: CreateTicket) {
        try {
            const httpResponse = await api.post('/api/ticket', data)
            return httpResponse
        } catch (error: any) {
            return error.response
        }
    },

    async createOwnTicket(data: CreateOwnTicket) {
        try {
            const httpResponse = await api.post('/api/ticket/create-own', data)
            return httpResponse
        } catch (error: any) {
            return error.response
        }
    },

    async createTicketsForSome(data: CreateTicket) {
        try {
            const httpResponse = await api.post('/api/tickets/create-for-some', data)
            return httpResponse
        } catch (error: any) {
            return error.response
        }
    },

    async createTicketsForAll(data: CreateTicketFoAllAttendees) {
        try {
            const httpResponse = await api.post('/api/tickets/create-for-all', data)
            return httpResponse
        } catch (error: any) {
            return error.response
        }
    },

    async createTicketType(data: CreateTicketType) {
        const httpResponse = await api.post('/api/ticket-type', data)
        return httpResponse
    },


    async deleteTicketType(ticketTypeId: string) {
        const httpResponse = await api.delete(`/api/ticket-type/${ticketTypeId}`)
        return httpResponse
    },

    async updateTicketType(data: UpdateTicketTypeType) {
        const httpResponse = await api.put(`/api/ticket-type`, data)
        return httpResponse
    },

    async cancelTicket(data: CancelTicketType) {
        const httpResponse = await api.put(`/api/ticket/cancel`, data)
        return httpResponse
    },

    async confirmTicketSubmission(data: ConfirmTicketSubmissionType) {
        const httpResponse = await api.put(`/api/ticket/send`, data)
        return httpResponse
    },

    async downloadTicket(params: DownloadTicketType) {
        return await api.get(`/api/ticket/download`, {
            params,
            responseType: 'blob',
        })
    },

    async updatePassword(params: ChangePasswordType) {
        const { id, ...data } = params;
        const httpResponse = await api.put(`api/user/password/${id}`, data)
        return httpResponse
    },

    async confirmUser(data: ConfirmUserType) {
        const httpResponse = await api.put(`/api/confirm-user/${data.token}`)
        return httpResponse
    },

    async resendConfirmationUserToken(email: string) {
        const httpResponse = await api.put('/api/resend-token/confirm-user', { email })
        return httpResponse
    },

    async requestPresenceConfirmation(data: { attendeeId: string }) {
        const httpResponse = await api.post('/api/attendee/request-presence-confirmation', data)
        return httpResponse
    },

    async requestPresenceConfirmationToPendings(data: { eventId: string }) {
        const httpResponse = await api.put('/api/attendee/confirmation/pendings', data)
        return httpResponse
    },

    async dashboardDetails(eventId: string) {
        const httpResponse = await api.get(`/api/dashboard/${eventId}`)
        return httpResponse
    },

    async getInTouch(data: { message: string, name: string, email: string }) {
        const httpResponse = await api.post(`/api/get-in-touch`, data)
        return httpResponse
    },

    async getWeddingById(weddingId: string) {
        const httpResponse = await api.get(`/api/event/wedding/${weddingId}`)
        return httpResponse
    },

    async updateWeddingSettings(data: EventWeddingModel) {
        const httpResponse = await api.put(`/api/event/wedding`, data)
        return httpResponse
    },

    async scan(ticketCode: string) {
        const httpResponse = await api.put(`/api/ticket/scan`, { ticketCode })
        return httpResponse
    },

    async getAttendeeRelations(attendeeId: string) {
        const params = { attendeeId }
        const httpResponse = await api.get('/api/ticket/get-by-relationship', { params })
        return httpResponse
    },
}

export default AxiosClient;