import { Dispatch, SetStateAction, createContext } from 'react';

type Props = {
  isAuth: boolean,
  setIsAuth: Dispatch<SetStateAction<boolean>>
}

const AuthContext = createContext<Props>({
  isAuth: false,
  setIsAuth: () => false
});

export default AuthContext;
