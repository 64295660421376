import React from 'react'
import RootRouter from './routes'
import { ThemeProvider } from '@mui/material/styles'
import theme from 'src/themes/theme'
import ProviderWrapper from 'src/context/providers/wrapper';
import Loader from './components/Loader';
import Toaster from './components/Toaster';
import './assets/styles/global.css';

const LoadingProvider = ProviderWrapper['loading']();
const AuthProvider = ProviderWrapper['isAuth']();
const ActiveMenuProvider = ProviderWrapper['activeMenu']();
const SelectedEventProvider = ProviderWrapper['selectedEvent']();
const CurrentUserProvider = ProviderWrapper['currentUser']();

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <LoadingProvider>
        <AuthProvider>
          <ActiveMenuProvider>
            <CurrentUserProvider>
              <Toaster>
                <SelectedEventProvider>
                  <Loader />
                  <RootRouter />
                </SelectedEventProvider>
              </Toaster>
            </CurrentUserProvider>
          </ActiveMenuProvider>
        </AuthProvider>
      </LoadingProvider>
    </ThemeProvider>
  )
}

export default App
