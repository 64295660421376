import { CircularProgress, Backdrop } from "@mui/material"
import { useContext } from "react";
import ContextWrapper from "src/context/context/wrapper";
import theme from "src/themes/theme";

const Loader = () => {
    const { loading } = useContext(ContextWrapper['loading']());

    const styles = {
        viewModal: {
            color: theme.palette.grey[50],
            zIndex: (theme: any) => theme.zIndex.drawer + 1000,
            alignItems: 'center',
        },
        loading: {
            textAlign: 'center'
        }
    }

    return (
        <Backdrop
            open={loading}
            sx={styles.viewModal}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

export default Loader