import React, {
    useState,
    ReactNode
} from 'react';

import ContextWrapper from '../context/wrapper';

type Props = {
    children: ReactNode;
};

const LoadingContext = ContextWrapper['loading']();

const LoadingProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    const value = { loading, setLoading };
    return (
        <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
    );
};

export default LoadingProvider;