import { useCallback, useContext, useEffect, useState, } from "react";
import Lottie from "lottie-react";
import { enqueueSnackbar } from 'notistack';
import { Box, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosClient from "src/clients/axios.client";

import ContextWrapper from "src/context/context/wrapper";
import successAnimation from "./success-animation.json";
import errorAnimation from "./error-animation.json";
import loadingAnimation from "./loading-animation.json";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const UserConfirmation = () => {
    const { setIsAuth } = useContext(ContextWrapper['isAuth']());
    const navigate = useNavigate();
    const query = useQuery();
    const token = query.get('token');

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const onLogout = useCallback(() => {
        localStorage.removeItem('isAuth');
        setIsAuth(false)
        navigate('/login');
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('avatar');
    }, [navigate, setIsAuth]);

    const handleSubmit = useCallback(async () => {
        try {
            if (!token) {
                setError(true)
                return
            }
            const params = {
                token,
            }
            const httpResponse = await AxiosClient.confirmUser(params)
            if (httpResponse.status === 200) {
                setLoading(false)
                enqueueSnackbar('Sucesso', { variant: 'success' })
                setTimeout(() => {
                    onLogout()
                }, 3000)

            } else {
                setError(true)
                setLoading(false)
                enqueueSnackbar('Falha', { variant: 'error' })
            }

        } catch (error) {
            setError(true)
            setLoading(false)
            enqueueSnackbar('Falha', { variant: 'error' })
        }
    }, [onLogout, token])

    useEffect(() => {
        if (token) {
            handleSubmit();
        } else {
            onLogout()
        }
    }, [handleSubmit, token]);

    return (
        <Box sx={styles.container}>
            {loading ?
                <Box>
                    <Lottie animationData={loadingAnimation} loop={true} />
                    <Typography sx={styles.text}>Confirmando conta ...</Typography>
                </Box>
                : error ? <Box>
                    <Lottie animationData={errorAnimation} />
                    <Typography sx={styles.text}>Falha ao confirmar conta!!</Typography>
                </Box>
                    : <Box>
                        <Typography sx={styles.text}>Conta confirmada com sucesso!!</Typography>
                        <Lottie animationData={successAnimation} loop={true} />
                    </Box>
            }
        </Box>
    );
};

const styles = {
    container: {
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fonstSize: 20,
        textAlign: 'center'
    }
}

export default UserConfirmation;
