import React from 'react'
import {
  Box,
  Typography,
  Modal,
  Stack,
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './RemoveCoordModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: () => void
  email: string
}

const RemoveCoordModal: React.FC<Props> = ({ email, showModal, setShowModal, submit }) => {

  const handleClose = (): void => { setShowModal(false) }
  const handleSubmit = (): void => {
    submit()
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Remover Coordenador</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Typography>Você tem certeza que deseja remover o coordenador {email}?</Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 5 }}
          >
            <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
            <Button onClick={handleSubmit}>Remover</Button>
          </Stack>
        </Box>

      </Box>
    </Modal>
  )
}

export default RemoveCoordModal
