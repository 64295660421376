import { Dispatch, SetStateAction, createContext } from 'react';

type Props = {
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>
}

const LoadingContext = createContext<Props>({
  loading: false,
  setLoading: () => false
});

export default LoadingContext;
