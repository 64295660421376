import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Modal,
  Stack,
  CircularProgress,
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './RestoreModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'
import AxiosClient from 'src/clients/axios.client'
import { EventModel } from 'src/data/models'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: () => void
  eventId: string
}

const RestoreEventModal: React.FC<Props> = ({
  eventId,
  showModal,
  setShowModal,
  submit }) => {
  const [loading, setLoading] = useState(true)
  const [event, setEvent] = useState<EventModel>({
    agenda: [""],
    budget: 0,
    userId: "",
    cancellationReason: "",
    createdAt: "",
    description: "",
    internalNotes: "",
    dressCode: "",
    endAt: new Date(),
    startAt: new Date(),
    eventType: "",
    id: "",
    isCancelled: false,
    isRSVPRequired: true,
    isTicketed: false,
    location: "",
    registrationDeadline: new Date(),
    speakers: [""],
    sponsors: [""],
    theme: "",
    maxTicketQuantity: 0,
    title: "",
    updatedAt: "",
    maxAgeForFreeAdmission: 0,
    isPrivate: false,
    ticketsCreated: 0,
  })

  const getEvent = useCallback(async () => {
    await AxiosClient.getEventById({ eventId, setLoading, setEvent })
  }, [eventId])

  useEffect(() => {
    getEvent()
  }, [getEvent])

  const handleClose = (): void => { setShowModal(false) }
  const handleSubmit = (): void => {
    submit()
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Restaurar Evento</Header>
        </Box>
        {loading ?
          <Box sx={styles.loaderContainer}>
            <CircularProgress color="secondary" sx={styles.loading} />
          </Box> :
          <Box sx={styles.innerContainer}>
            <Typography sx={styles.question}>Você tem certeza que deseja restaurar o evento "{event.title}"?</Typography>
            <Typography sx={styles.reason}>O evento havia sido cancelado pelo motivo: "{event.cancellationReason || 'Não há motivos'}"</Typography>
            <Typography sx={styles.attention}>Atenção! Todos os convites cancelados serão restaurados.</Typography>
            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              sx={{ paddingTop: 5 }}
            >
              <Button onClick={handleClose}>Fechar</Button>
              <Button onClick={handleSubmit}>Restaurar</Button>
            </Stack>
          </Box>
        }
      </Box>
    </Modal>
  )
}

export default RestoreEventModal
