import React, { useCallback, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { Box, Modal, Stack, TextField } from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "./DetailsAttendeeModal.css";

import Button from "src/components/Button";
import Header from "src/components/Header";
import styles from "./styles";
import theme from "src/themes/theme";
import AxiosClient from "src/clients/axios.client";
import { format } from "date-fns";
import { AttendeeModel } from "src/data/models";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  selectedAttendee: AttendeeModel;
}

const DetailsAttendeeModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  selectedAttendee,
}) => {
  const [attendee, setAttendee] = useState<AttendeeModel>(selectedAttendee);

  const handleClose = (): void => {
    setShowModal(false);
  };
  const getAttendee = useCallback(async () => {
    const params = { id: selectedAttendee.attendeeId };
    AxiosClient.getAttendee(params)
      .then((resp) => setAttendee(resp.data))
      .catch(() => enqueueSnackbar("Erro ao buscar.", { variant: "error" }));
  }, [selectedAttendee]);

  useEffect(() => {
    getAttendee();
  }, [getAttendee]);

  const formatDate = (date: string | Date) =>
    format(new Date(date), "dd/MM/yyyy HH:mm:ss");

  return (
    <Modal open={showModal} onClose={handleClose}>
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>
            Detalhes do Participante
          </Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
          >
            <Box sx={styles.row}>
              <TextField
                placeholder="Ex: João da Silva"
                label="Nome do Participante:"
                value={attendee?.firstName}
                variant="standard"
                onChange={() => null}
                disabled
                InputLabelProps={{
                  shrink: true,
                }}
                sx={styles.textField}
              />
              <TextField
                sx={styles.textField}
                id="email"
                label="Email"
                placeholder="Ex: joao@gmail.com"
                variant="standard"
                disabled
                value={attendee?.email}
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box sx={styles.row}>
              <TextField
                sx={styles.textField}
                id="cpf"
                label="CPF"
                placeholder="Ex: XXX.XXX.XXX-XX"
                variant="standard"
                value={attendee?.cpf}
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              <TextField
                sx={styles.textField}
                id="celular"
                label="Celular"
                placeholder="Ex: (XX) XXXXX XXXX"
                variant="standard"
                value={attendee?.phone}
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Box>
            <Box sx={styles.row}>
              <TextField
                sx={styles.textField}
                id="accessibilityNeeds"
                label="Necessidades de Acessibilidade"
                placeholder="Ex: rampa"
                variant="standard"
                value={attendee?.accessibilityNeeds}
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              <TextField
                sx={styles.textField}
                id="notes"
                label="Notas"
                placeholder="Informações adicionais do participante"
                variant="standard"
                value={attendee?.internalNotes}
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Box>

            <Box sx={styles.row}>
              <TextField
                sx={styles.textField}
                id="registeredBy"
                label="Cadastro por:"
                variant="standard"
                value={attendee?.userDetails?.email}
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              <TextField
                sx={styles.textField}
                id="registrationDate"
                label="Cadastro:"
                variant="standard"
                value={
                  attendee?.registrationDate &&
                  formatDate(attendee?.registrationDate)
                }
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
              <TextField
                sx={styles.textField}
                id="registrationStatus"
                label="Status de Cadastro:"
                variant="standard"
                value={
                  attendee?.registrationStatus === "confirmed"
                    ? "Confirmado"
                    : attendee?.registrationStatus === "pending"
                      ? "Pendente"
                      : "Cancelado"
                }
                onChange={() => null}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled
              />
            </Box>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 5 }}
          >
            <Button
              variant="outlined"
              bgColor="transparent"
              onClick={handleClose}
            >
              Fechar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default DetailsAttendeeModal;
