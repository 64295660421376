import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Stack, Typography, Button, Tabs, Tab } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { enqueueSnackbar } from 'notistack';

import AxiosClient from "src/clients/axios.client";
import Container from "src/components/Container";
import InnerContainer from "src/components/InnerContainer";
import ContextWrapper from "src/context/context/wrapper";
import { styles } from "./styles";
import UpdateEventModal from "../Events/components/UpdateModal/UpdateModal";
import { UpdateEvent, UpdateEventSchedule } from "src/clients/types";
import AddCoordinatorModal from "../Events/components/AddCoordinatorModal/AddCoordinatorModal";
import EventScheduleModal from "./components/EventScheduleModal/EventScheduleModal";
import RemoveCoordModal from "./components/RemoveCoordModal/RemoveCoordModal";
import Sidebar from "src/components/Sidebar";
import { CustomTabPanel } from "./components/CustomTabPanel";
import { Details } from "./components/Details";
import { CoordinatorModel, EventModel, TempCoordinatorsModel } from "src/data/models";
import { Attendees } from "./components/Attendees";
import { TicketTypes } from "./components/TicketType";
import { Tickets } from "./components/Tickets";
import { DashBoard } from "./components/DashBoard";
import { Settings } from "./components/Settings";

const EventDetails = () => {
    const { selectedEvent } = useContext(ContextWrapper['selectedEvent']());
    const { setActiveMenu } = useContext(ContextWrapper['activeMenu']());
    const [value, setValue] = React.useState(0);
    const [loading, setLoading] = useState(false)
    const [loadingSchedule, setLoadingSchedule] = useState(false)
    const [, setLoadingCoordinators] = useState(true)
    const [showUpdateModal, setShowUpdateModal] = useState(false)
    const [showRemoveCoordModal, setShowRemoveCoordModal] = useState(false)
    const [showRemoveTempCoordModal, setShowRemoveTempCoordModal] = useState(false)
    const [selectedCoordinator, setSelectCoordinator] = useState<{
        user: { email: string },
        eventId: string
        userId: string
    } | null>(null)
    const [selectedTempCoordinator, setSelectTempCoordinator] = useState<{
        email: string
    } | null>(null)
    const [showAddCoord, setShowAddCoord] = useState(false)
    const [showSchedule, setShowSchedule] = useState(false)
    const [coordinators, setCoordinators] = useState<{ rows: CoordinatorModel[], count: number }>({ rows: [], count: 0 })
    const [tempCoordinators, setTempCoordinators] = useState<{ rows: TempCoordinatorsModel[], count: number }>({ rows: [], count: 0 })
    const [event, setEvent] = useState<EventModel>({
        agenda: [""],
        budget: 0,
        userId: "",
        cancellationReason: "",
        createdAt: "",
        description: "",
        internalNotes: "",
        dressCode: "",
        endAt: new Date(),
        startAt: new Date(),
        eventType: "",
        id: "",
        isCancelled: false,
        isRSVPRequired: true,
        isTicketed: false,
        location: "",
        registrationDeadline: new Date(),
        speakers: [""],
        sponsors: [""],
        theme: "",
        maxTicketQuantity: 0,
        title: "",
        updatedAt: "",
        maxAgeForFreeAdmission: 0,
        isPrivate: false,
        ticketsCreated: 0,
    })
    const [userEventAssociation, setUserEventAssociation] = useState<CoordinatorModel | undefined>(undefined)

    const getEvent = useCallback(async () => {
        await AxiosClient.getEventById({ eventId: selectedEvent, setLoading, setEvent })
    }, [selectedEvent])

    const getAssociation = useCallback(async () => {
        try {
            const userId = localStorage.getItem('id');
            if (!userId) return;
            setLoading(true)
            const httpResponse = await AxiosClient.getAssociation(userId, selectedEvent)
            setUserEventAssociation(httpResponse.data)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setCoordinators({ rows: [], count: 0 })
            enqueueSnackbar('Falha ao buscar dados. Tente Novamente!', { variant: 'error' })
        }
    }, [selectedEvent])

    const listCoordinators = useCallback(async () => {
        try {
            const params = {
                eventId: selectedEvent,
                page: 0,
                pageSize: 100,
            }
            setLoadingCoordinators(true)
            const httpResponse = await AxiosClient.getEventCoordinators(params)
            setLoadingCoordinators(false)
            setCoordinators(httpResponse.data)
        } catch (error) {
            setCoordinators({ rows: [], count: 0 })
            setLoadingCoordinators(false)
            enqueueSnackbar('Erro ao buscar coordenadores.', { variant: 'error' })
        }
    }, [selectedEvent])

    const getTemporaryCoordinators = useCallback(async () => {
        try {
            const params = {
                eventId: selectedEvent,
                page: 0,
                pageSize: 100,
            }
            setLoadingCoordinators(true)
            const httpResponse = await AxiosClient.getTemporaryCoordinators(params)
            setTempCoordinators(httpResponse.data)
            setLoadingCoordinators(false)
        } catch (error) {
            setLoadingCoordinators(false)
            enqueueSnackbar('Erro ao buscar coordenadores.', { variant: 'error' })
        }
    }, [selectedEvent])

    const submitUpdate = async (data: UpdateEvent) => {
        try {
            setLoading(true)
            const httpResponse = await AxiosClient.updateEvent(data, event.id)
            setLoading(false)
            if (httpResponse.status === 200) {
                getEvent()
                setShowUpdateModal(false)
                enqueueSnackbar('Sucesso', { variant: 'success' })
            } else {
                enqueueSnackbar('Erro ao atualizar.', { variant: 'error' })
            }
        } catch (err) {
            setLoading(false)
        }
    }

    const submitAddCoordinator = async (emailList: string[]) => {
        try {
            setLoading(true)
            const params = { emails: emailList, eventId: event.id }
            const httpResponse = await AxiosClient.createCoordinator(params)
            setLoading(false)
            if (httpResponse.status === 200) {
                listCoordinators()
                getTemporaryCoordinators()
                setShowAddCoord(false)

                httpResponse.data.success.map((email: string) => enqueueSnackbar(`Email: ${email}`, { variant: "success" }))
                httpResponse.data.error.map((email: string) => enqueueSnackbar(`Email: ${email}`, { variant: "error" }))

            } else {
                enqueueSnackbar(`Erro ao criar coordenador.`, { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Erro ao remover.', { variant: "error" })
            setLoading(false)
        }
    }

    const submitRemoveCoordinator = async () => {
        try {
            setLoading(true)
            if (!selectedCoordinator) return;
            const params = { eventId: selectedEvent, userId: selectedCoordinator.userId }
            const httpResponse = await AxiosClient.removeCoordinator(params)
            setLoading(false)
            if (httpResponse.status === 200) {
                listCoordinators()
                setShowRemoveCoordModal(false)
                enqueueSnackbar('Sucesso', { variant: "success" })
                setSelectCoordinator(null)
            } else {
                enqueueSnackbar('Erro ao remover.', { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Erro ao remover.', { variant: "error" })
            setLoading(false)
        }
    }

    const submitRemoveTempCoordinator = async () => {
        try {
            setLoading(true)
            if (!selectedTempCoordinator) return;
            const params = { eventId: event.id, email: selectedTempCoordinator?.email || '-' }
            const httpResponse = await AxiosClient.removeTempCoordinator(params)
            setLoading(false)
            if (httpResponse.status === 200) {
                getTemporaryCoordinators()
                setShowRemoveTempCoordModal(false)
                enqueueSnackbar('Sucesso', { variant: "success" })
                setSelectTempCoordinator(null)
            } else {
                enqueueSnackbar('Erro ao remover.', { variant: "error" })
            }
        } catch (error) {
            enqueueSnackbar('Erro ao remover.', { variant: "error" })
            setLoading(false)
        }
    }

    const handleRemoveCoordinator = (data: any) => {
        setSelectCoordinator(data)
        setShowRemoveCoordModal(true)
    }

    const handleRemoveTemporaryCoordinator = (data: any) => {
        setSelectTempCoordinator(data)
        setShowRemoveTempCoordModal(true)
    }

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const submitSchedule = async (data: UpdateEvent | UpdateEventSchedule) => {
        try {
            setLoadingSchedule(true)
            const httpResponse = await AxiosClient.updateEvent(data, event.id)
            setLoadingSchedule(false)
            if (httpResponse.status === 200) {
                getEvent()
                setShowSchedule(false)
                enqueueSnackbar('Sucesso', { variant: "success" })
            } else {
                enqueueSnackbar('Erro ao atualizar.', { variant: "error" })
            }
        } catch (error) {
            setLoadingSchedule(false)
        }
    }

    useEffect(() => {
        if (!selectedEvent) return;
        getAssociation()
        getEvent()
        listCoordinators()
        getTemporaryCoordinators()
    }, [getAssociation, listCoordinators, getEvent, getTemporaryCoordinators, selectedEvent])



    return (
        <Sidebar>
            <Container>
                <InnerContainer>
                    <Stack direction="row" spacing={0}>
                        {loading ?
                            <Box sx={styles.loaderContainer}>
                                <CircularProgress color="secondary" sx={styles.loading} />
                            </Box>
                            :
                            <>
                                <Tooltip title="Voltar">
                                    <Button sx={[styles.backBtn, {}]} onClick={() => setActiveMenu('Eventos')}>Eventos</Button>
                                </Tooltip>
                                <Typography sx={styles.crevron}>
                                    {`>`}
                                </Typography>
                                <Typography sx={styles.headerTitle}>
                                    {`${event.title}`}
                                </Typography>
                            </>
                        }
                    </Stack>
                </InnerContainer>
                <InnerContainer>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Dashboard" {...a11yProps(0)} />
                        <Tab label="Detalhes" {...a11yProps(1)} />
                        <Tab label="Participantes" {...a11yProps(2)} />
                        <Tab label="Tipos de Convites" {...a11yProps(3)} />
                        <Tab label="Convites" {...a11yProps(4)} />
                        <Tab label="Configurações" {...a11yProps(5)} />
                    </Tabs>

                    <CustomTabPanel value={value} index={0}>
                        <DashBoard />
                    </CustomTabPanel>

                    <CustomTabPanel value={value} index={1}>
                        <Details
                            event={event}
                            coordinators={coordinators}
                            tempCoordinators={tempCoordinators}
                            userEventAssociation={userEventAssociation}
                            handleShowUpdateModal={setShowUpdateModal}
                            handleRemoveCoordinator={handleRemoveCoordinator}
                            handleRemoveTemporaryCoordinator={handleRemoveTemporaryCoordinator}
                            handleShowAddCoord={setShowAddCoord}
                            handleShowSchedule={setShowSchedule}
                        />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={2}>
                        <Attendees />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={3}>
                        <TicketTypes />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={4}>
                        <Tickets />
                    </CustomTabPanel>
                    {
                        event.weddingDetails && (
                            <CustomTabPanel value={value} index={5}>
                                <Settings id={event.weddingDetails?.id} eventType={event.eventType} />
                            </CustomTabPanel>

                        )
                    }
                </InnerContainer>

                {showUpdateModal &&
                    <UpdateEventModal
                        showModal={showUpdateModal}
                        setShowModal={setShowUpdateModal}
                        eventId={event.id}
                        submit={submitUpdate}
                    />
                }
                {showAddCoord &&
                    <AddCoordinatorModal
                        showModal={showAddCoord}
                        setShowModal={setShowAddCoord}
                        eventId={event.id}
                        submit={submitAddCoordinator}
                    />
                }
                {showSchedule &&
                    <EventScheduleModal
                        eventId={event.id}
                        loading={loadingSchedule}
                        setLoading={setLoadingSchedule}
                        showModal={showSchedule}
                        setShowModal={setShowSchedule}
                        submit={submitSchedule}
                    />
                }
                {showRemoveCoordModal &&
                    <RemoveCoordModal
                        showModal={showRemoveCoordModal}
                        setShowModal={setShowRemoveCoordModal}
                        email={selectedCoordinator?.user?.email || '-'}
                        submit={submitRemoveCoordinator}
                    />
                }
                {showRemoveTempCoordModal &&
                    <RemoveCoordModal
                        showModal={showRemoveTempCoordModal}
                        setShowModal={setShowRemoveTempCoordModal}
                        email={selectedTempCoordinator?.email || '-'}
                        submit={submitRemoveTempCoordinator}
                    />
                }
            </Container>
        </Sidebar>
    )
}

export default EventDetails;