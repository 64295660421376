import { Box, Typography } from "@mui/material"

const NoFoundData = () => {
    const styles = {
        container: {
            display: 'flex',
            height: '400px',
            alignText: 'center',
            justifyContent: 'center',
            alignItens: 'center',
        },
        txt: {
            alignSelf: 'center'
        }
    }

    return (
    <Box style={styles.container}>
        <Typography sx={styles.txt}>
            Não há dados encontrados
        </Typography>
    </Box>
)
}

export default NoFoundData