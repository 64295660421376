import React, { useMemo } from "react";
import { Box, Modal, Stack, Typography, Checkbox } from "@mui/material";
import {
  Email as EmailIcon,
  WhatsApp as WhatsAppIcon,
  PermContactCalendar as PermContactCalendarIcon,
} from '@mui/icons-material';
import "react-datepicker/dist/react-datepicker.css";
import "./ConfirmTicketSubmissionModal.css";

import Button from "src/components/Button";
import Header from "src/components/Header";
import styles from "./styles";
import theme from "src/themes/theme";
import { TicketType } from "../../types";
import { phoneNumberMask } from "src/utils/phoneNumberMask";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  submit: (data: any) => void;
  ticketDetails: TicketType | null;
}

const ConfirmTicketSubmissionModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  submit,
  ticketDetails,
}) => {
  const [method, setMethod] = React.useState<'whatsapp' | 'email' | 'manual' | undefined>(undefined);

  const handleWhatsappMethod = () => {
    if (method === 'whatsapp') {
      setMethod(undefined);
    } else {
      setMethod('whatsapp');
    }
  };

  const handleEmailMethod = () => {
    if (method === 'email') {
      setMethod(undefined);
    } else {
      setMethod('email');
    }
  };

  const handleManualMethod = () => {
    if (method === 'manual') {
      setMethod(undefined);
    } else {
      setMethod('manual');
    }
  };

  const isDisabled = useMemo(() => {
    if (method === undefined) {
      return true;
    }
    return false;
  }, [method]);

  const handleClose = (): void => {
    setShowModal(false);
  };

  const handleSubmit = (): void => {
    const data = {
      attendeeId: ticketDetails?.attendeeId,
      ticketId: ticketDetails?.ticketId,
      isSent: true,
      sendBy: method
    };
    submit(data);
  };

  return (
    <Modal open={showModal} onClose={handleClose}>
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Enviar Ingresso</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
              "& .MuiAutocomplete-root": { m: 1, width: "100%" },
            }}
          >
            <Box sx={[styles.contactContainer, { paddingBottom: 1 }]}>
              <PermContactCalendarIcon sx={styles.icon} />
              <Typography sx={styles.contact}>
                {ticketDetails?.ticketAttendee?.firstName}
              </Typography>
            </Box>
            <Box sx={[styles.contactContainer, { paddingBottom: 1 }]}>
              <EmailIcon sx={styles.icon} />
              <Typography sx={styles.contact}>
                {ticketDetails?.ticketAttendee?.email ||
                  "Não há email cadastrado"}
              </Typography>
            </Box>
            <Box sx={styles.contactContainer}>
              <WhatsAppIcon sx={styles.icon} />
              <Typography sx={styles.contact}>
                {ticketDetails?.ticketAttendee?.phone ?
                  phoneNumberMask(ticketDetails.ticketAttendee.phone.replace("+55", "")) :
                  "Não há celular cadastrado"}
              </Typography>
            </Box>

            <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
              Enviar por:
            </Typography>
            {ticketDetails?.ticketAttendee?.email && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                sx={{ alignItems: "center", paddingLeft: 2 }}
              >
                <Checkbox
                  checked={method === 'email'}
                  onChange={handleEmailMethod}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ padding: 0 }}
                />
                <Typography sx={styles.checkboxTxt}>Email</Typography>
              </Stack>
            )}
            {ticketDetails?.ticketAttendee?.phone && (
              <Stack
                direction="row"
                spacing={1}
                justifyContent="flex-start"
                sx={{ alignItems: "center", paddingLeft: 2 }}
              >
                <Checkbox
                  checked={method === 'whatsapp'}
                  onChange={handleWhatsappMethod}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{ padding: 0 }}
                />
                <Typography sx={styles.checkboxTxt}>Whatsapp</Typography>
              </Stack>
            )}

            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              sx={{ alignItems: "center", paddingLeft: 2 }}
            >
              <Checkbox
                checked={method === 'manual'}
                onChange={handleManualMethod}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: 0 }}
              />
              <Typography sx={styles.checkboxTxt}>Manual</Typography>
            </Stack>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 5 }}
          >
            <Button
              variant="outlined"
              bgColor="transparent"
              onClick={handleClose}
            >
              Fechar
            </Button>
            <Button onClick={handleSubmit} isDisable={isDisabled}>
              Confirmar Envio
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmTicketSubmissionModal;
