import theme from "src/themes/theme";

const styles = {
    cardsContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        padding: 2,
        alignItems: 'flex-start',
        width: 350,
    },
    warningIcon: {
        color: theme.palette.primary.main,
        fontSize: 50,
    },
    value: {
        color: theme.palette.grey[700],
        fontSize: 40,
        fontWeight: '700'
    },
    info: {
        color: theme.palette.grey[600],
        fontSize: 16,
    },
    infoType: {
        color: theme.palette.grey[700],
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'left'
    },
    sectionTitle: {
        color: theme.palette.grey[700],
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'left',
        paddingTop: 5,
        paddingBottom: 5,
    },
    attendeeList: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        paddingBottom: 2,
    },
    item: {
        textAlign: 'left',
        flex: 1,
        color: theme.palette.grey[700],
        paddingBottom: 1,
    }
}
export default styles;