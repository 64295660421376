import { useContext, useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";

import {
    Stack,
    Tooltip,
    Button as MUIButton,
    TableContainer,
    Table,
    TableHead,
    TableCell,
    TableRow,
    TableBody,
    Box,
    SpeedDial,
    SpeedDialIcon,
    SpeedDialAction,
    Paper,
    TableFooter,
    TablePagination,
    IconButton,
    InputBase,
    Divider,
} from "@mui/material";
import {
    Refresh as RefreshIcon,
    DeleteForever as DeleteForeverIcon,
    Edit as EditIcon,
    PostAdd as PostAddIcon,
    ContentCopy as ContentCopyIcon,
    Search as SearchIcon,
} from "@mui/icons-material";

import AxiosClient from "src/clients/axios.client";

import theme from "src/themes/theme";
import ContextWrapper from "src/context/context/wrapper";
import { CreateTicketType, TicketTypeType } from "./types";
import NoFoundData from "src/components/NoFoundData";
import CreateTicketTypeModal from "./CreateTicketTypeModal/CreateTicketTypeModal";
import DeleteTicketTypeModal from "./DeleteTicketTypeModal/DeleteTicketTypeModal";
import UpdateTicketTypeModal from "./UpdateTicketTypeModal/UpdateTicketTypeModal";

const actions = [
    { icon: <DeleteForeverIcon />, name: "Excluir" },
    { icon: <EditIcon />, name: "Editar" },
    { icon: <ContentCopyIcon />, name: "Copiar" },
];

const TicketTypes: React.FC = () => {
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const { selectedEvent } = useContext(ContextWrapper["selectedEvent"]());
    const [ticketType, setTicketType] = useState("");
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(5);
    const [list, setList] = useState<{ rows: TicketTypeType[]; count: number }>({
        rows: [],
        count: 0,
    });
    const [selectedTicketType, setSelectTicketType] =
        useState<TicketTypeType | null>(null);
    const [showCreateTicketTypeModal, setShowCreateTicketTypeModal] =
        useState<boolean>(false);
    const [showDeleteTicketTypeModal, setShowDeleteTicketTypeModal] =
        useState<boolean>(false);
    const [showUpdateTicketTypeModal, setShowUpdateTicketTypeModal] =
        useState<boolean>(false);

    const handleChangePage = (e: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const copyLinkToClipboard = async (ticketTypeCode: string) => {
        try {
            const domain = window.location.hostname;
            let link = `http://${domain}/generate-ticket?ticketTypeCode=${ticketTypeCode}`;
            if (process.env.REACT_APP_ENV === "DEV") {
                link = `http://${domain}:${process.env.REACT_APP_PORT}/generate-ticket?ticketTypeCode=${ticketTypeCode}`;
            }
            await navigator.clipboard.writeText(link);
            // Opcional: Mostrar uma mensagem de sucesso
            alert("Link copiado com sucesso!");
        } catch (err) {
            // Opcional: Mostrar uma mensagem de erro caso a cópia falhe
            alert("Falha ao copiar o link.");
        }
    };
    const handleActions = (actionName: string, ticketType: TicketTypeType) => {
        setSelectTicketType(ticketType);
        if (actionName === "Excluir") {
            setShowDeleteTicketTypeModal(true);
        }
        if (actionName === "Editar") {
            setShowUpdateTicketTypeModal(true);
        }
        if (actionName === "Copiar") {
            copyLinkToClipboard(ticketType.ticketTypeCode);
        }
    };

    const handleSubmit = () => {
        if (page === 0) {
            getTicketTypes();
        } else {
            setPage(0);
            setRowsPerPage(5);
        }
    };

    const getTicketTypes = () => {
        const params: any = {
            page: +page,
            pageSize: +rowsPerPage,
            eventId: selectedEvent,
        };

        if (ticketType) {
            params.name = ticketType;
        }
        AxiosClient.getTicketOptionsList(params)
            .then((httpResponse) => {
                const data = httpResponse.data as {
                    rows: TicketTypeType[];
                    count: number;
                };
                setList(data);
                setLoading(false);
            })
            .catch((error) => {
                enqueueSnackbar("Erro ao buscar tipos de ingressos", {
                    variant: "error",
                });
                setLoading(false);
            });
    };

    const submitCreateTicketType = async (data: CreateTicketType) => {
        try {
            setLoading(true);
            const params = { ...data, eventId: selectedEvent };
            const httpResponse = await AxiosClient.createTicketType(params);
            setLoading(false);
            if (httpResponse.status === 200) {
                getTicketTypes();
                setShowCreateTicketTypeModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao criar.", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Erro ao criar.", { variant: "error" });
            setLoading(false);
        }
    };

    const submitDeleteTicketType = async () => {
        try {
            if (!selectedTicketType?.ticketTypeId) return;
            setLoading(true);
            const httpResponse = await AxiosClient.deleteTicketType(
                selectedTicketType?.ticketTypeId
            );
            setLoading(false);
            if (httpResponse.status === 200) {
                getTicketTypes();
                setShowDeleteTicketTypeModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao deletar.", { variant: "error" });
            }
        } catch (error) {
            enqueueSnackbar("Erro ao deletar.", { variant: "error" });
            setLoading(false);
        }
    };

    const submitUpdateTicketType = async (data: TicketTypeType) => {
        try {
            if (!data?.ticketTypeId) return;
            setLoading(true);
            const httpResponse = await AxiosClient.updateTicketType(data);
            setLoading(false);
            if (httpResponse.status === 200) {
                getTicketTypes();
                setShowUpdateTicketTypeModal(false);
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Erro ao atualizar.", { variant: "error" });
            }
        } catch (error: any) {
            enqueueSnackbar(
                error.response.data.error.message || "Erro ao atualizar.",
                { variant: "error" }
            );
            setLoading(false);
        }
    };

    useEffect(() => {
        getTicketTypes();
    }, [page, rowsPerPage, selectedEvent]);

    const styles = {
        header: {
            color: theme.palette.grey[700],
            fontSize: 25,
            fontWeight: "600",
        },
        textField: {
            width: "150px",
            "& label.Mui-focused": {
                color: theme.palette.secondary.main,
            },
            "& .MuiOutlinedInput-root": {
                borderRadius: "2px",
                backgroundColor: theme.palette.grey[200],
                "&.Mui-focused fieldset": {
                    borderRadius: "2px",
                    borderColor: theme.palette.secondary.main,
                },
            },
        },
        speedDial: {
            "& .MuiFab-primary": {
                alignSelf: "center",
                backgroundColor: "green",
                "& .MuiSpeedDialIcon-icon": {
                    fontSize: 18,
                    color: "white",
                    alignSelf: "center",
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 20,
                    padding: 1,
                },
            },
            "&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight": {
                top: theme.spacing(2),
                left: theme.spacing(2),
            },
        },
        table: {
            width: "100%",
            maxWidth: "100%",
        },
        tableFooter: {
            zIndex: 1,
            display: "flex",
            justifyContent: "center",
        },
        tablePagination: {
            borderBottom: "none",
        },
    };

    return (
        <Box>
            <Stack
                direction="row"
                spacing={1}
                justifyContent="space-between"
                sx={{ marginBottom: 5 }}
            >
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Paper
                        component="form"
                        sx={{
                            p: "2px 4px",
                            display: "flex",
                            alignItems: "center",
                            width: 350,
                            boxShadow: "none", // Remove a sombra
                            border: "1px solid", // Adiciona uma borda
                            borderColor: "grey.400",
                            height: '34px',
                            borderRadius: '2px'
                        }}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            onChange={(e) => setTicketType(e?.target.value)}
                            placeholder="Pesquisar tipo de convite..."
                            inputProps={{ "aria-label": "pesquisar tipo de convite" }}
                        />
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton
                            color="primary"
                            sx={{ p: "10px" }}
                            aria-label="directions"
                            onClick={handleSubmit}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Paper>
                </Stack>
                <Stack direction="row" spacing={1} justifyContent="space-between">
                    <Tooltip title="Atualizar">
                        <MUIButton variant="contained" onClick={() => getTicketTypes()}>
                            <RefreshIcon />
                        </MUIButton>
                    </Tooltip>
                    <Tooltip title={"Criar Tipo de Ingresso (Ex. Vip, Camarote..)"}>
                        <div style={{ display: "flex" }}>
                            <MUIButton
                                variant="contained"
                                onClick={() => setShowCreateTicketTypeModal(true)}
                            >
                                <PostAddIcon />
                            </MUIButton>
                        </div>
                    </Tooltip>
                </Stack>
            </Stack>
            {list.count ? (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Nome</TableCell>
                                    <TableCell>Valor (R$)</TableCell>
                                    <TableCell>Quantidade</TableCell>
                                    <TableCell>Ing. Gerados</TableCell>
                                    <Tooltip title="Se o próprio usuário consegue gerar o ingresso">
                                        <TableCell>Auto Gerado</TableCell>
                                    </Tooltip>
                                    <TableCell sx={{ width: "10%" }}>Mais</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {list.rows.map((ticket) => (
                                    <TableRow key={ticket.ticketTypeId}>
                                        <TableCell>{ticket.name}</TableCell>
                                        <TableCell>{ticket.price}</TableCell>
                                        <TableCell>
                                            {ticket.maxTicketTypeQuantity || "Não informada"}
                                        </TableCell>
                                        <TableCell>{ticket.ticketsTypeCreated}</TableCell>
                                        <TableCell>
                                            {ticket.ticketCreationEnabled ? "Sim" : "Não"}
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    width: "0px",
                                                }}
                                            >
                                                <SpeedDial
                                                    ariaLabel="SpeedDial basic example"
                                                    direction="left"
                                                    sx={styles.speedDial}
                                                    icon={<SpeedDialIcon />}
                                                >
                                                    {actions.map((action, index) => {
                                                        if (
                                                            !ticket.ticketCreationEnabled &&
                                                            action.name === "Copiar"
                                                        )
                                                            return null;
                                                        return (
                                                            <SpeedDialAction
                                                                key={index}
                                                                icon={action.icon}
                                                                tooltipTitle={action.name}
                                                                onClick={(e) =>
                                                                    handleActions(action.name, ticket)
                                                                }
                                                            />
                                                        );
                                                    })}
                                                </SpeedDial>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TableContainer component={Paper}>
                        <Table sx={styles.table}>
                            <TableFooter sx={styles.tableFooter}>
                                <TableRow>
                                    <TablePagination
                                        sx={styles.tablePagination}
                                        rowsPerPageOptions={[5, 10, 25]}
                                        count={list.count}
                                        rowsPerPage={+rowsPerPage}
                                        page={+page}
                                        SelectProps={{
                                            inputProps: {
                                                "aria-label": "Rows per page",
                                            },
                                            native: true,
                                        }}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>
                </>
            ) : (
                <NoFoundData />
            )}
            {showCreateTicketTypeModal && (
                <CreateTicketTypeModal
                    showModal={showCreateTicketTypeModal}
                    setShowModal={setShowCreateTicketTypeModal}
                    submit={submitCreateTicketType}
                />
            )}
            {showDeleteTicketTypeModal && selectedTicketType && (
                <DeleteTicketTypeModal
                    showModal={showDeleteTicketTypeModal}
                    setShowModal={setShowDeleteTicketTypeModal}
                    submit={submitDeleteTicketType}
                    details={selectedTicketType}
                />
            )}
            {showUpdateTicketTypeModal && selectedTicketType && (
                <UpdateTicketTypeModal
                    showModal={showUpdateTicketTypeModal}
                    setShowModal={setShowUpdateTicketTypeModal}
                    submit={submitUpdateTicketType}
                    details={selectedTicketType}
                />
            )}
        </Box>
    );
};

export default TicketTypes;
