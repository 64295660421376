import theme from 'src/themes/theme'

const styles = {
  button: {
    height: 40,
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    fontWeight: 'bold',
    minWidth: 130,
    borderRadius: 0.5,
  }
}
export default styles
