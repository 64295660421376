import React from "react";

type Props = {
  color?: string;
  size?: number;
}

const LogoSVG: React.FC<Props> = ({ color, size = 500 }) => {
  const scale = size / 500;

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 500 500"
      xmlns="http://www.w3.org/2000/svg"
    >
      <style>
        @import url('https://fonts.googleapis.com/css2?family=Bebas+Neue:wght@700&display=swap');
      </style>

      <path d={`M ${100 * scale} ${200 * scale} A ${160 * scale} ${160 * scale} 0 0 1 ${400 * scale} ${200 * scale}`} stroke={color || "black"} strokeWidth={8 * scale} fill="none" />
      <path d={`M ${120 * scale} ${200 * scale} A ${140 * scale} ${140 * scale} 0 0 1 ${380 * scale} ${200 * scale}`} stroke={color || "black"} strokeWidth={8 * scale} fill="none" />
      <path d={`M ${380 * scale} ${300 * scale} A ${140 * scale} ${140 * scale} 0 0 1 ${120 * scale} ${300 * scale}`} stroke={color || "black"} strokeWidth={8 * scale} fill="none" />
      <path d={`M ${400 * scale} ${300 * scale} A ${160 * scale} ${160 * scale} 0 0 1 ${100 * scale} ${300 * scale}`} stroke={color || "black"} strokeWidth={8 * scale} fill="none" />

      <line x1={230 * scale} y1={170 * scale} x2={240 * scale} y2={180 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
      <line x1={250 * scale} y1={180 * scale} x2={250 * scale} y2={160 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
      <line x1={260 * scale} y1={180 * scale} x2={270 * scale} y2={170 * scale} stroke={color || "black"} strokeWidth={5 * scale} />

      <text
        x={`${50 * scale}%`}
        y={`${52 * scale}%`}
        font-family="'Bebas Neue', sans-serif"
        font-size={90 * scale}
        font-weight="700"
        fill={color || "black"}
        text-anchor="middle"
        alignment-baseline="middle"
      >
        EVENTO
      </text>
      <text
        x={`${50 * scale}%`}
        y={`${63 * scale}%`}
        font-family="'Bebas Neue', sans-serif"
        font-size={40 * scale}
        font-weight="700"
        fill={color || "black"}
        text-anchor="middle"
        alignment-baseline="middle"
      >
        SEM FILA
      </text>

      <line x1={231 * scale} y1={350 * scale} x2={229 * scale} y2={355 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
      <line x1={241 * scale} y1={350 * scale} x2={239 * scale} y2={355 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
      <line x1={251 * scale} y1={350 * scale} x2={249 * scale} y2={355 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
      <line x1={261 * scale} y1={350 * scale} x2={259 * scale} y2={355 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
      <line x1={271 * scale} y1={350 * scale} x2={269 * scale} y2={355 * scale} stroke={color || "black"} strokeWidth={5 * scale} />
    </svg>
  );
};

export default LogoSVG;
