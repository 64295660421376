import React, { useState, useMemo, ChangeEvent } from 'react'
import {
  Box,
  Modal,
  Stack,
  MenuItem
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './UpdateTicketTypeModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'
import { formatCurrency } from 'src/utils/formatCurrency'
import { convertToNumber } from 'src/utils/convertToNumber'
import { TicketTypeModel } from 'src/data/models'
import { CustomInputField, CustomSelectInputField } from 'src/components/TextField'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: (data: any) => void
  details: TicketTypeModel
}

const UpdateTicketTypeModal: React.FC<Props> = ({ showModal, setShowModal, submit, details }) => {
  const [name, setName] = useState<string>(details.name)
  const [price, setPrice] = useState<number>(+details.price)
  const [ticketType, setTicketType] = useState(+details.price === 0 ? 'free' : 'paid');
  const [defineTicketQuantity, setDefineTicketQuantity] = useState(details.maxTicketTypeQuantity ? 'yes' : 'not');
  const [ticketsQuantity, setTicketsQuantity] = useState<number | undefined>(details.maxTicketTypeQuantity || undefined);

  const isDisabled = useMemo(() => {
    return !name
  }, [name])

  const handleTicketsQuantity = (val: string): void => { setTicketsQuantity(+val) }

  const handleDefineTicketQuantity = (event: ChangeEvent<HTMLInputElement>) => {
    setDefineTicketQuantity(event.target.value);
    if (!event.target.value) {
      setTicketsQuantity(undefined)
    }
  };

  const handleTicketTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    setTicketType(event.target.value);
  };

  const handleClose = (): void => {
    setShowModal(false)
  }
  const handleSubmit = (): void => {
    const data = {
      price: ticketType === 'free' ? 0 : price,
      ticketTypeId: details.ticketTypeId,
      eventId: details.eventId,
      maxTicketTypeQuantity: defineTicketQuantity === 'yes' ? ticketsQuantity : null,
    }
    submit(data)
  }

  const handleChange = (setter: (value: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  const handlePriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = convertToNumber(event.target.value);
    setPrice(numberValue); // Atualiza o estado com o valor numérico
  };

  // Ao exibir o valor, use a função de formatação
  const displayedPrice = formatCurrency(price);

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Editar Tipo de Ingresso</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
          >
            <Box sx={{ marginBottom: 2 }}>
              <CustomInputField
                label='Nome do convite'
                placeholder='Ex. Vip, Camarote, etc..'
                value={name}
                onChange={handleChange(setName)}
                disabled
                maxLength={50}
              />
            </Box>
            <Box sx={{ marginBottom: 2 }}>
              <CustomSelectInputField
                label='Tipo de convite'
                value={ticketType}
                onChange={handleTicketTypeChange}
              >
                {['free', 'paid'].map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {`${option === 'free' ? 'Grátis' : 'Pago'}`}
                  </MenuItem>
                ))}
              </CustomSelectInputField>
            </Box>

            {ticketType === 'paid' &&
              <Box sx={{ marginBottom: 2 }}>
                <CustomInputField
                  label='Valor'
                  placeholder='(R$)'
                  value={displayedPrice}
                  onChange={handlePriceChange}
                />
              </Box>
            }

            <Box sx={{ marginBottom: 2 }}>
              <CustomSelectInputField
                label='Definir quantidade de ingressos?'
                value={defineTicketQuantity}
                onChange={handleDefineTicketQuantity}
              >
                {['yes', 'not'].map((option) => (
                  <MenuItem
                    key={option}
                    value={option}
                  >
                    {`${option === 'yes' ? 'Sim' : 'Não'}`}
                  </MenuItem>
                ))}
              </CustomSelectInputField>
            </Box>
            {defineTicketQuantity === 'yes' && (
              <Box sx={{ marginBottom: 2 }}>
                <CustomInputField
                  placeholder="Nº Ingressos"
                  label="Nº Ingressos"
                  type="number"
                  value={ticketsQuantity}
                  onChange={(
                    event: ChangeEvent<HTMLInputElement>
                  ) => {
                    handleTicketsQuantity(
                      event.target.value
                    )
                  }}
                />
              </Box>
            )}
          </Box>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 3 }}
          >
            <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
            <Button onClick={handleSubmit} isDisable={isDisabled}>Atualizar</Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default UpdateTicketTypeModal

