import React from 'react';
import { Container, Typography, Paper, CssBaseline, AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TermsOfService = () => {
    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" color="inherit" noWrap>
                        Evento Sem Fila - Termos de Serviço
                    </Typography>
                    <Button color="inherit" onClick={handleBackToLogin}>
                        Voltar ao Login
                    </Button>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="md" style={{ marginTop: '2rem' }}>
                <Paper elevation={3} style={{ padding: '2rem' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Termos de Serviço
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Última atualização: 16/08/2024
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        1. Aceitação dos Termos
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Ao acessar ou usar os serviços do Evento Sem Fila, você concorda em cumprir e estar sujeito aos Termos de Serviço e a nossa Política de Privacidade.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        2. Descrição do Serviço
                    </Typography>
                    <Typography variant="body1" paragraph>
                        O Evento Sem Fila é uma plataforma que auxilia organizadores de eventos na gestão e controle de entradas de eventos gratuitos e pagos.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        3. Elegibilidade
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Você deve ter ao menos 18 anos de idade para utilizar o Evento Sem Fila.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        4. Contas de Usuário
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Ao criar uma conta em nossa plataforma, você é responsável por manter a confidencialidade de suas credenciais.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        5. Direitos e Obrigações dos Organizadores de Eventos
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Os organizadores são responsáveis por fornecer todas as informações corretas e necessárias sobre seus eventos.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        6. Política de Pagamento
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Para eventos pagos, o Evento Sem Fila facilita a compra de ingressos através de meios de pagamento terceiros.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        7. Política de Reembolso
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Os organizadores de eventos são os únicos responsáveis por estabelecer suas políticas de reembolso.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        8. Cancelamento de Eventos
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Os organizadores têm o direito de cancelar seus eventos, e os participantes deverão ser informados por meio da plataforma.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        9. Direitos de Propriedade Intelectual
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Todo o conteúdo disponível no Evento Sem Fila é de propriedade do Evento Sem Fila ou de seus licenciantes.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        10. Limitação de Responsabilidade
                    </Typography>
                    <Typography variant="body1" paragraph>
                        O Evento Sem Fila não se responsabiliza por qualquer dano, perda ou prejuízo decorrente de falhas técnicas.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        11. Modificações nos Termos
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Reservamo-nos o direito de alterar estes Termos de Serviço a qualquer momento.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        12. Lei Aplicável
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Estes Termos de Serviço serão regidos e interpretados de acordo com as leis brasileiras.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        13. Contato
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Caso tenha alguma dúvida sobre estes Termos de Serviço, entre em contato conosco através do nosso e-mail: suporte@eventosemfila.com.br.
                    </Typography>
                </Paper>
            </Container>
        </>
    );
};

export default TermsOfService;
