import React, {
    useState,
    ReactNode
} from 'react';

import ContextWrapper from '../context/wrapper';

type Props = {
    children: ReactNode;
};

const AuthContext = ContextWrapper['isAuth']();

const AuthProvider = ({ children }: Props) => {
    const [isAuth, setIsAuth] = useState<boolean>(false);

    const value = { isAuth, setIsAuth };
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;