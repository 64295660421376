import React, {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Modal,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  TextField,
  TableContainer,
  Paper,
  TableFooter,
  TablePagination,
  InputAdornment,
  IconButton,
  Stack,
  CircularProgress,
  MenuItem,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import WarningIcon from '@mui/icons-material/Warning';

import styles from "./styles";
import Header from "src/components/Header";
import Button from "src/components/Button";
import theme from "src/themes/theme";
import ContextWrapper from "src/context/context/wrapper";
import AxiosClient from "src/clients/axios.client";
import { AttendeeType, TicketTypeType } from "./types";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  submit: (data: any, createToAllAttendees: boolean) => void;
}

const PAYMENT_METHOD_LIST = [
  { value: "creditCard", label: "Cartão de Crédito" },
  { value: "debitCard", label: "Cartão de Débito" },
  { value: "pix", label: "Pix" },
  { value: "bankTransfer", label: "Transferência Bancária" },
  { value: "payPal", label: "PayPal" },
  { value: "cash", label: "Dinheiro" },
  { value: "other", label: "Outro" },
];

const PAYMENT_STATUS = [
  { value: "paidout", label: "Pago" },
  { value: "pending", label: "Pendente" },
];

const CreateTicketModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  submit,
}) => {
  const { selectedEvent } = useContext(ContextWrapper["selectedEvent"]());
  const [, setAttendeeLoading] = useState(false);
  const [attendeeList, setAttendeeList] = useState<{
    rows: AttendeeType[];
    count: number;
  }>({ rows: [], count: 0 });
  const [selectedAttendees, setSelectedAttendees] = useState<{
    [key: string]: boolean;
  }>({});
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [activeStep, setActiveStep] = useState(0);
  const [filter, setFilter] = useState("");
  const [ticketTypeLoading, setTicketTypeLoading] = useState(false);
  const [ticketOptionsList, setTicketOptionsList] = useState<{
    rows: TicketTypeType[];
    count: number;
  }>({ rows: [], count: 0 });
  const [selectedTicketType, setSelectedTicketType] = useState<
    TicketTypeType | undefined
  >(undefined);
  const [paymentStatus, setPaymentStatus] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [sendTicket, setSendTicket] = useState(true);
  const [createForAllAtendees, setCreateForAllAttendees] = useState(false);
  const [method, setMethod] = React.useState<
    "whatsapp" | "email" | "manual" | "donotsend" | undefined
  >(undefined);

  const isDisabled = useMemo(() => {
    if (activeStep === 0) {
      return !Object.keys(selectedAttendees).some(
        (el) => selectedAttendees[el] === true
      );
    } else if (!selectedTicketType) {
      return !selectedTicketType;
    } else if (+selectedTicketType.price !== 0) {
      return !paymentStatus || !paymentMethod;
    } else return false;
  }, [
    activeStep,
    paymentMethod,
    paymentStatus,
    selectedAttendees,
    selectedTicketType,
  ]);

  const handlePaymentMethod = (val: any) => setPaymentMethod(val);
  const handlePaymentStatus = (val: any) => setPaymentStatus(val);
  const handleClose = () => {
    setShowModal(false);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleChangePage = (e: any, newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setPageSize(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };
  const handleTicketType = (ticketTypeId: string) => {
    const ticket = ticketOptionsList.rows.find(
      (option) => option.ticketTypeId === ticketTypeId
    );
    setSelectedTicketType(ticket);
  };

  const handleSearch = () => {
    const params = {
      page: currentPage,
      pageSize,
      eventId: selectedEvent,
      firstName: filter,
    };
    setAttendeeLoading(true);
    AxiosClient.getAttendeeList(params)
      .then((response) => {
        const data = response.data as { rows: AttendeeType[]; count: number };
        const attendeesObj = handleAttendeeList(
          data.rows,
          createForAllAtendees
        );
        setSelectedAttendees((prevState) => ({
          ...prevState,
          ...attendeesObj,
        }));
        setAttendeeList(data);
        setAttendeeLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao obter lista de participantes:", error);
        setAttendeeLoading(false);
      });
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleSendTicket = () => {
    setSendTicket(!sendTicket);
    if (sendTicket) {
      setMethod("donotsend");
    }
  };

  const handleWhatsappMethod = () => {
    if (method === "whatsapp") {
      setMethod(undefined);
    } else {
      setMethod("whatsapp");
    }
  };

  const handleEmailMethod = () => {
    if (method === "email") {
      setMethod(undefined);
    } else {
      setMethod("email");
    }
  };

  const handleManualMethod = () => {
    if (method === "manual") {
      setMethod(undefined);
    } else {
      setMethod("manual");
    }
  };

  const handleAttendeeList = (data: AttendeeType[], isChecked: boolean) => {
    let handledObjAttendees = {};
    data.forEach((item) => {
      handledObjAttendees = {
        ...handledObjAttendees,
        [item.attendeeId]:
          selectedAttendees[item.attendeeId] !== undefined
            ? selectedAttendees[item.attendeeId]
            : isChecked,
      };
    });
    return handledObjAttendees;
  };

  const handleSelectAll = (event: any) => {
    const isChecked = event.target.checked;
    setCreateForAllAttendees(isChecked);
    if (isChecked) {
      const handledSelected = { ...selectedAttendees };
      Object.keys(handledSelected).forEach((chave) => {
        handledSelected[chave] = true;
      });
      setSelectedAttendees(handledSelected);
    } else {
      const handledSelected = { ...selectedAttendees };
      Object.keys(handledSelected).forEach((chave) => {
        handledSelected[chave] = false;
      });
      setSelectedAttendees(handledSelected);
    }
  };

  const handleCheckboxChange = (event: any, id: string) => {
    const isChecked = event.target.checked;
    setSelectedAttendees((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));
  };

  const fetchAttendeeList = useCallback(async () => {
    const params = {
      page: currentPage,
      pageSize,
      eventId: selectedEvent,
      name: filter,
    };
    setAttendeeLoading(true);
    AxiosClient.getAttendeeList(params)
      .then((response) => {
        const data = response.data as { rows: AttendeeType[]; count: number };
        const attendeesObj = handleAttendeeList(
          data.rows,
          createForAllAtendees
        );
        setSelectedAttendees((prevState) => ({
          ...prevState,
          ...attendeesObj,
        }));
        setAttendeeList(data);
        setAttendeeLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao obter lista de participantes:", error);
        setAttendeeLoading(false);
      });
  }, [currentPage, pageSize, selectedEvent]);

  const fetchTicketOptionsList = useCallback(async () => {
    const params = {
      page: 0,
      pageSize: 200,
      eventId: selectedEvent,
    };
    setTicketTypeLoading(true);
    AxiosClient.getTicketOptionsList(params)
      .then((response) => {
        const data = response.data as { rows: TicketTypeType[]; count: number };
        setTicketOptionsList(data);
        setTicketTypeLoading(false);
      })
      .catch((error) => {
        console.error("Erro ao obter lista de opções de ticket:", error);
        setTicketTypeLoading(false);
      });
  }, [selectedEvent]);

  const handleSubmit = (): void => {
    const attendees = Object.keys(selectedAttendees).filter(
      (chave) => selectedAttendees[chave] === true
    );
    if (!attendees.length) return;
    const data: any = {
      ticketTypeId: selectedTicketType?.ticketTypeId,
      paymentStatus: selectedTicketType?.price ? paymentStatus : "paidout",
      paymentMethod: selectedTicketType?.price ? paymentMethod : "other",
      promoCode: "",
      seatNumber: "",
      sendBy: method
    };
    if (!createForAllAtendees) data.attendeeId = attendees;
    submit(data, createForAllAtendees);
  };

  useEffect(() => {
    fetchAttendeeList();
  }, [currentPage, fetchAttendeeList]);

  useEffect(() => {
    if (activeStep === 1) {
      fetchTicketOptionsList();
    }
  }, [fetchTicketOptionsList, activeStep]);

  const stepContent = (step: any) => {
    switch (step) {
      case 0:
        return (
          <>
            <Box sx={[styles.row, { marginTop: 1, marginBottom: 1 }]}>
              <TextField
                label="Pesquisar participantes..."
                fullWidth
                size="small"
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                onKeyPress={handleKeyPress}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleSearch}>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={createForAllAtendees}
                        onChange={(event) => handleSelectAll(event)}
                      />
                    </TableCell>
                    <TableCell sx={styles.p0}>Nome</TableCell>
                    <TableCell sx={styles.p0}>Email</TableCell>
                    <TableCell sx={styles.p0}>Celular</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendeeList.rows.map((item) => (
                    <TableRow key={item.attendeeId} sx={{ padding: 1 }}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={!!selectedAttendees[item.attendeeId]}
                          onChange={(event) =>
                            handleCheckboxChange(event, item.attendeeId)
                          }
                          disabled={createForAllAtendees}
                        />
                      </TableCell>
                      <TableCell sx={styles.p0}>{item.firstName}</TableCell>
                      <TableCell sx={styles.p0}>{item.email || "-"}</TableCell>
                      <TableCell sx={styles.p0}>{item.phone || "-"}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer component={Paper}>
              <Table sx={styles.table}>
                <TableFooter sx={styles.tableFooter}>
                  <TableRow>
                    <TablePagination
                      sx={styles.tablePagination}
                      rowsPerPageOptions={[5]}
                      count={attendeeList.count}
                      rowsPerPage={+pageSize}
                      page={+currentPage}
                      SelectProps={{
                        inputProps: {
                          "aria-label": "Rows per page",
                        },
                        native: true,
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </>
        );
      case 1:
        return (
          <Box sx={{}}>
            <Box sx={[styles.row, { marginTop: 1 }]}>
              <TextField
                fullWidth
                size="small"
                id="eventType"
                select
                label="Tipo de Ingresso"
                value={
                  selectedTicketType ? selectedTicketType?.ticketTypeId : ""
                }
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleTicketType(event.target.value);
                }}
                InputProps={{
                  endAdornment: ticketTypeLoading && (
                    <CircularProgress color="inherit" size={20} />
                  ),
                }}
              >
                {ticketOptionsList.rows.map((option) => (
                  <MenuItem
                    key={option.ticketTypeId}
                    value={option.ticketTypeId}
                  >
                    {`${option.name} (R$ ${option.price})`}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            {selectedTicketType?.price ? (
              <Box sx={[styles.row, { marginTop: 1 }]}>
                <TextField
                  sx={[{ marginRight: 1 }]}
                  fullWidth
                  size="small"
                  id="paymentMethod"
                  select
                  label="Método de Pagamento"
                  value={paymentMethod}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handlePaymentMethod(event.target.value);
                  }}
                >
                  {PAYMENT_METHOD_LIST.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={[{ marginLeft: 1 }]}
                  fullWidth
                  size="small"
                  id="paymentStatus"
                  select
                  label="Status de Pagamento"
                  value={paymentStatus}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handlePaymentStatus(event.target.value);
                  }}
                >
                  {PAYMENT_STATUS.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            ) : null}

            <Stack
              direction="row"
              spacing={1}
              justifyContent="flex-start"
              sx={{ alignItems: "center", paddingTop: 3 }}
            >
              <Checkbox
                checked={sendTicket}
                onChange={handleSendTicket}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ padding: 0 }}
              />
              <Typography sx={styles.checkboxTxt}>Enviar Ingresso</Typography>
            </Stack>
            {sendTicket && (
              <>
                <Box sx={styles.warningContainer}>
                  <WarningIcon sx={styles.warningIcon} />
                  <Box>
                    <Typography sx={[styles.warning, { fontSize: 16, fontWeight: 'bold' }]}>
                      Atenção!
                    </Typography>
                    <Typography sx={styles.warning}>
                      Caso o participante não possua o meio selecionado, o
                      ingresso será enviado por outro meio alternativo (email ou
                      whatsapp).
                    </Typography>
                  </Box>
                </Box>

                <Typography sx={{ paddingTop: 2, paddingBottom: 2 }}>
                  Enviar por:
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  sx={{ alignItems: "center", paddingLeft: 2 }}
                >
                  <Checkbox
                    checked={method === "email"}
                    onChange={handleEmailMethod}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ padding: 0 }}
                  />
                  <Typography sx={styles.checkboxTxt}>Email</Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  sx={{ alignItems: "center", paddingLeft: 2 }}
                >
                  <Checkbox
                    checked={method === "whatsapp"}
                    onChange={handleWhatsappMethod}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ padding: 0 }}
                  />
                  <Typography sx={styles.checkboxTxt}>Whatsapp</Typography>
                </Stack>

                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-start"
                  sx={{ alignItems: "center", paddingLeft: 2 }}
                >
                  <Checkbox
                    checked={method === "manual"}
                    onChange={handleManualMethod}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ padding: 0 }}
                  />
                  <Typography sx={styles.checkboxTxt}>Manual</Typography>
                </Stack>
              </>
            )}
          </Box>
        );
      default:
        return "Unknown step";
    }
  };

  return (
    <Modal open={showModal}>
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Criar Ingresso</Header>
        </Box>
        <Box sx={styles.contentContainer}>
          <Box sx={{ height: 370, overflow: "scroll" }}>
            {stepContent(activeStep)}
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 1 }}
          >
            <Button
              variant="outlined"
              bgColor="transparent"
              onClick={activeStep === 0 ? handleClose : handleBack}
            >
              {activeStep === 0 ? "Fechar" : "Voltar"}
            </Button>
            <Button
              onClick={activeStep === 0 ? handleNext : handleSubmit}
              isDisable={isDisabled}
            >
              {activeStep === 0 ? "Próximo" : "Criar"}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateTicketModal;
