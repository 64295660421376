import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Box, Typography, Button, Avatar, IconButton, Tooltip, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Props = {
    showModal: boolean
    setShowModal: (val: boolean) => void
    handleSaveAvatar: (avatar: any) => void
}

const ChangeAvatarModal: React.FC<Props> = ({ showModal, setShowModal, handleSaveAvatar }) => {
    const [avatar, setAvatar] = useState(null);
    const [fileError, setFileError] = useState('');

    const onDrop = useCallback((acceptedFiles: any) => {
        const file = acceptedFiles[0];
        setFileError('');

        if (file.size > 2 * 1024 * 1024) {
            setFileError('O arquivo é muito grande. Tamanho máximo: 2MB.');
            return;
        }
        const reader = new FileReader() as any;

        reader.onloadend = () => {
            setAvatar(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, accept: {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png']
        }
    });
    return (
        <Modal open={showModal}>
            <Box sx={styles.modalStyle}>
                <Tooltip title="Fechar">
                    <IconButton
                        aria-label="close"
                        onClick={() => setShowModal(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                </Tooltip>
                <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
                    Trocar Avatar
                </Typography>
                <Box {...getRootProps()} sx={styles.dropzoneStyle}>
                    <input {...getInputProps()} />
                    <p>Arraste e solte uma imagem aqui, ou clique para selecionar</p>
                    {fileError && <Alert severity="error">{fileError}</Alert>}
                </Box>
                {avatar && (
                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        <Avatar src={avatar} alt="Avatar Preview" sx={{ width: 100, height: 100 }} />
                    </Box>
                )}
                <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="contained" color="primary" onClick={() => handleSaveAvatar(avatar)}>
                        Salvar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

const styles = {
    modalStyle: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
    },
    
    dropzoneStyle: {
        border: '2px dashed #1565c0',
        borderRadius: '5px',
        padding: '20px',
        marginTop: '15px',
        textAlign: 'center',
        cursor: 'pointer',
        color: '#1565c0',
    }
}

export default ChangeAvatarModal;
