import LoadingContext from './loadingContext';
import SelectedEventContext from './selectedEventContext';
import AuthContext from './authContext';
import ActiveMenuContext from './activeMenuContext';
import CurrentUserContext from './currentUserContext';

const ContextWrapper = {
  loading: () => LoadingContext,
  selectedEvent: () => SelectedEventContext,
  isAuth: () => AuthContext,
  activeMenu: () => ActiveMenuContext,
  currentUser: () => CurrentUserContext,
};

export default ContextWrapper;
