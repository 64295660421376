import theme from 'src/themes/theme'

const styles = {
  viewModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24
    // p: 4,
  },
  innerContainer: {
    p: 4
  },
  headerContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    p: '10px',
    paddingLeft: 4
  },
  label: {
    alignSelf: 'center',
    width: '25%'
  },
  dateLabel: {
    alignSelf: 'center',
    fontSize: '12px',
    color: theme.palette.grey[600],
  },
  pickerRow: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '10px',
    marginLeft: '5px'
  },
  ticket: { alignSelf: 'flex-start' },
  row: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start'
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start'
  },
  checkboxTxt: {
    fontSize: 14
  },
  helpIcon: {
    fontSize: 16,
    paddingLeft: 1,
    color: theme.palette.primary.dark,
    alignSelf: 'center',
  }
}
export default styles
