import { Dispatch, SetStateAction, createContext } from 'react';

type Props = {
  activeMenu: string,
  setActiveMenu: Dispatch<SetStateAction<string>>
}

const ActiveMenuContext = createContext<Props>({
  activeMenu: 'Eventos',
  setActiveMenu: () => 'Eventos'
});

export default ActiveMenuContext;
