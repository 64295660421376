import { Container, Typography, Paper, CircularProgress, Alert, Box } from '@mui/material';
import BackgroundImage from 'src/assets/images/bg_image.jpg';
import LogoSVG from "src/assets/images/logo";

type PropsType = {
    loading: boolean,
    error: string
}

function ConfirmationPage({ loading, error }: PropsType) {

    return (
        <div
            style={{
                height: '100vh',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={styles.logoBox}>
                <LogoSVG color="white" size={200} />
            </Box>
            <Container maxWidth="sm">
                <Paper elevation={6} style={{ padding: '30px', marginTop: '20px' }}>
                    <Typography variant="h4" component="h1" gutterBottom align="center" style={{ fontSize: '1.5rem' }}>
                        Confirmação de Cadastro
                    </Typography>
                    {loading ? (
                        <CircularProgress />
                    ) : error ? (
                        <Alert severity="error">{error}</Alert>
                    ) : (
                        <Typography variant="h5" component="h1" gutterBottom>
                            Coordenador confirmado com sucesso!
                        </Typography>
                    )}
                </Paper>
            </Container>
        </div>
    );
}

const styles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
        width: "100%",
    },
    logoBox: {
        marginBottom: "20px",
        position: "absolute",
        left: 0,
        top: 0,
    },
};

export default ConfirmationPage;
