import React from 'react';
import { Container, Typography, Paper, Box, CssBaseline, AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';


const PrivacyPolicy = () => {
    const navigate = useNavigate();

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <>
            <CssBaseline />
            <AppBar position="static">
                <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h6" color="inherit" noWrap>
                        Evento Sem Fila - Política de Privacidade
                    </Typography>
                    <Button color="inherit" onClick={handleBackToLogin}>
                        Voltar ao Login
                    </Button>
                </Toolbar>
            </AppBar>
            <Container component="main" maxWidth="md" style={{ marginTop: '2rem' }}>
                <Paper elevation={3} style={{ padding: '2rem' }}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Política de Privacidade
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Última atualização: 14/07/2024
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        1. Informações que Coletamos
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        1.1 Informações Pessoais
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Podemos coletar as seguintes informações pessoais:
                    </Typography>
                    <Box component="ul">
                        <li>Nome</li>
                        <li>Endereço de e-mail</li>
                        <li>Número de telefone</li>
                        <li>Informações de pagamento (quando aplicável)</li>
                        <li>Dados de login e senha</li>
                    </Box>
                    <Typography variant="h6" component="h3" gutterBottom>
                        1.2 Informações de Uso
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Coletamos informações sobre como você usa nosso software e aplicativo, incluindo:
                    </Typography>
                    <Box component="ul">
                        <li>Dados de navegação</li>
                        <li>Informações sobre dispositivos (tipo, sistema operacional, etc.)</li>
                        <li>Endereço IP</li>
                        <li>Cookies e tecnologias semelhantes</li>
                    </Box>
                    <Typography variant="h6" component="h3" gutterBottom>
                        1.3 Informações de Eventos
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Coletamos informações relacionadas aos eventos que você organiza, incluindo:
                    </Typography>
                    <Box component="ul">
                        <li>Dados dos participantes (nome, e-mail, telefone)</li>
                        <li>Informações sobre ingressos (tipo, data de emissão, status)</li>
                        <li>Comunicações enviadas aos participantes</li>
                    </Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                        2. Como Usamos Suas Informações
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        2.1 Fornecimento de Serviços
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Utilizamos suas informações para fornecer e melhorar nossos serviços, incluindo:
                    </Typography>
                    <Box component="ul">
                        <li>Gerenciamento e organização de eventos</li>
                        <li>Envio de solicitações de confirmação de presença</li>
                        <li>Envio de ingressos por e-mail e WhatsApp</li>
                        <li>Controle de acesso no dia do evento</li>
                    </Box>
                    <Typography variant="h6" component="h3" gutterBottom>
                        2.2 Comunicações
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Usamos suas informações para nos comunicarmos com você, incluindo:
                    </Typography>
                    <Box component="ul">
                        <li>Envio de atualizações e notificações importantes sobre seus eventos</li>
                        <li>Resposta a suas solicitações de suporte</li>
                        <li>Envio de newsletters e materiais promocionais (com seu consentimento)</li>
                    </Box>
                    <Typography variant="h6" component="h3" gutterBottom>
                        2.3 Melhorias e Personalização
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Usamos informações de uso para melhorar nosso software e aplicativo, personalizando sua experiência e implementando novas funcionalidades.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        3. Compartilhamento de Informações
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Não vendemos, alugamos ou compartilhamos suas informações pessoais com terceiros, exceto:
                    </Typography>
                    <Box component="ul">
                        <li>Quando necessário para fornecer nossos serviços (por exemplo, processadores de pagamento)</li>
                        <li>Quando exigido por lei ou para proteger nossos direitos legais</li>
                        <li>Com o seu consentimento</li>
                    </Box>
                    <Typography variant="h6" component="h2" gutterBottom>
                        4. Armazenamento e Segurança
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        4.1 Armazenamento de Dados
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Armazenamos suas informações em servidores seguros, utilizando medidas de segurança apropriadas para proteger contra acesso não autorizado, alteração, divulgação ou destruição de dados.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        4.2 Retenção de Dados
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Retemos suas informações pessoais pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade, a menos que um período de retenção mais longo seja exigido ou permitido por lei.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        5. Seus Direitos
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        5.1 Acesso e Correção
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Você tem o direito de acessar e corrigir suas informações pessoais. Pode fazê-lo acessando sua conta ou entrando em contato conosco.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        5.2 Exclusão
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Você pode solicitar a exclusão de suas informações pessoais, salvo quando necessário para a prestação de serviços ou conforme exigido por lei.
                    </Typography>
                    <Typography variant="h6" component="h3" gutterBottom>
                        5.3 Recusa de Comunicações
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Você pode optar por não receber comunicações promocionais seguindo as instruções de cancelamento de inscrição fornecidas nas comunicações.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        6. Alterações a Esta Política de Privacidade
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Podemos atualizar esta Política de Privacidade periodicamente. Notificaremos sobre mudanças significativas publicando a nova Política de Privacidade em nosso site e, quando apropriado, notificando você diretamente.
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        7. Contato
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco em:
                    </Typography>
                    <Box>
                        <Typography variant="body1">
                            <strong>Evento Sem Fila</strong>
                        </Typography>
                        <Typography variant="body1">
                            E-mail: contato@eventosemfila.com.br
                        </Typography>
                        {/* <Typography variant="body1">
              Endereço: [seu endereço físico]
            </Typography> */}
                    </Box>
                </Paper>
            </Container>
        </>
    );
};

export default PrivacyPolicy;
