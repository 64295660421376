export const validatePhoneNumber = (phoneNumber: string) => {
    // Remove tudo o que não é dígito
    const digits = phoneNumber.replace(/\D/g, '');

    // Verifica se tem 11 dígitos
    if (digits.length !== 11) return false;

    // Verifica se começa com um código de área válido (DDD) - opcional, dependendo da sua necessidade
    //const validDDD = /^(1[1-9]|2[12478]|2[9]|3[1-5]|3[7-9]|4[1-9]|5[1-5]|6[1-9]|7[1-3]|7[5-9]|8[1-9]|9[1-9])/.test(digits.substring(0, 2));
    //if (!validDDD) return false;

    // Verifica se o primeiro dígito após o DDD é 9
    if (digits[2] !== '9') return false;

    return true;
}
