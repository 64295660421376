import React, { useCallback, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, styled, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Props } from './types';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    paddingTop: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const Dropzone = styled('div')(({ theme }) => ({
  border: `2px dashed ${theme.palette.primary.main}`,
  padding: theme.spacing(4),
  textAlign: 'center',
  cursor: 'pointer',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.secondary,
}));

const FileUploadModal: React.FC<Props> = ({ showModal, setShowModal, submit }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onDragOver = (e: any) => {
    e.preventDefault();
  };

  const onDrop = useCallback((e: any) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files.length) {
      const file = files[0];
      if (file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        setSelectedFile(file);
      } else {
        alert("Please select an XLSX file.");
      }
    }
  }, []);

  const handleClose = () => {
    setShowModal(false);
  };

  const onFileChange = (e: any) => {
    const file = e.target.files[0];
    if (file && file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setSelectedFile(file);
    } else {
      alert("Please select an XLSX file.");
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      submit(selectedFile)
    }
  };

  const handleDownloadExample = () => {
    const link = document.createElement("a");
    link.href = process.env.PUBLIC_URL + '/template-convidados.xlsx'; // Caminho do arquivo na pasta public
    link.download = 'template-convidados.xlsx';
    link.click();
  };

  return (
    <StyledDialog open={showModal} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle>
        Carregar Arquivo XLSX
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <input
          accept=".xlsx"
          style={{ display: 'none' }}
          id="raised-button-file"
          type="file"
          onChange={onFileChange}
        />
        <label htmlFor="raised-button-file">
          <Dropzone onDragOver={onDragOver} onDrop={onDrop}>
            <Typography>Arraste e solte o arquivo XLSX aqui, ou clique para selecionar.</Typography>
          </Dropzone>
        </label>
        {selectedFile && (
          <Typography variant="subtitle1" style={{ marginTop: 20 }}>
            {selectedFile.name}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={handleDownloadExample}>
          Baixar Modelo
        </Button>
        <Button onClick={handleUpload} color="primary" variant="contained">
          Salvar
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

export default FileUploadModal;
