import theme from 'src/themes/theme'

const styles = {
  viewModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
  },
  innerContainer: {
    p: 4
  },
  headerContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    p: '10px',
    paddingLeft: 4
  },
  contentContainer: {
    padding: '8px 32px 32px 32px',
  },
  label: {
    alignSelf: 'center',
    width: '25%'
  },
  dateLabel: {
    alignSelf: 'center',
    fontSize: '12px',
    color: theme.palette.grey[600],
  },

  pickerRow: {
    width: '100%',
    marginTop: '5px',
    marginBottom: '10px',
    marginLeft: '5px'
  },
  ticket: { alignSelf: 'flex-start' },
  row: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
    flex: 1,
  },
  table: {
    width: '100%',
    maxWidth: '100%',
  },
  tableFooter: {
    zIndex: 1,
    display: 'flex',
    justifyContent: 'center',
  },
  tablePagination: {
    borderBottom: 'none',
  },
  stepper: {
    padding: 2,
  },
  p0: {
    padding: 0
  },
  textInput: {
    width: 400,
    marginBottom: 1,
    '& label.Mui-focused': {
      color: theme.palette.secondary.main
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '2px',
      backgroundColor: theme.palette.grey[200],
      '&.Mui-focused fieldset': {
        borderRadius: '2px',
        borderColor: theme.palette.secondary.main
      }
    }
  },
  checkboxTxt: {
    fontSize: 14
  },
  warningContainer: {
    backgroundColor: theme.palette.primary.light,
    padding: 1,
    display: 'flex',
    flexDirection: 'row',
    justtifyContent: 'center',
    alignItems: 'center',
    marginTop: 2,
  },
  warning: {
    fontSize: 11,
    color: theme.palette.grey[50],
  },
  warningIcon: {
    fontSize: 40,
    color: theme.palette.grey[50],
    paddingRight: 3,
    paddingLeft: 2,
  }
}
export default styles
