import { useContext, useState } from "react";
import {
    TextField,
    Button,
    Container,
    Link,
    Grid,
    IconButton,
    InputAdornment,
    FormHelperText,
    useMediaQuery,
    useTheme,
    Typography,
    Box,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LogoSVG from "src/assets/images/logo";
import BackgroundImage from "src/assets/images/bg_image.jpg";
import AxiosClient from "src/clients/axios.client";
import ContextWrapper from "src/context/context/wrapper";
import { enqueueSnackbar } from "notistack";
import { useLocation, useNavigate } from "react-router-dom";

const RecoveryPassword = () => {
    const navigate = useNavigate();
    const query = new URLSearchParams(useLocation().search);
    const resetToken = query.get("resetToken");
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const [password, setPassword] = useState("");
    const [isPasswordError, setIsPasswordError] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const passwordValidation = (): boolean => {
        let isFormInvalid = false;
        if (!password) {
            setIsPasswordError("O campo está vazio.");
            isFormInvalid = true;
            return isFormInvalid;
        }

        if (password !== confirmPassword) {
            setIsPasswordError(
                "Os campos Nova Senha e Confirmar Senha não conferem."
            );
            isFormInvalid = true;
            return isFormInvalid;
        }
        return isFormInvalid;
    };

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            if (passwordValidation()) {
                return;
            }
            if (!resetToken) {
                setError("Token Inválido");
                return;
            }
            const params = { password, resetToken };
            setLoading(true);
            await AxiosClient.recoveryPassword(params);
            setLoading(false);
            enqueueSnackbar("Senha recuperada com sucesso!", { variant: "success" });
            navigate("/login");
        } catch (error: any) {
            setLoading(false);
            setError("Falha ao recuperar senha");
            enqueueSnackbar("Falha ao recuperar senha", { variant: "error" });
        }
    };

    return (
        <div
            style={{
                height: "100vh",
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isMobile ? "0 10px" : "0",
            }}
        >
            <Box sx={styles.logoBox}>
                <LogoSVG color="white" size={200} />
            </Box>
            <Container component="main" maxWidth="xs">
                <Box
                    style={{
                        padding: "40px 20px",
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Typography sx={styles.title}>Recuperar Senha</Typography>
                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Senha"
                            type={showPassword ? "text" : "password"}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!isPasswordError}
                            helperText={isPasswordError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: "10px" }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirmar Senha"
                            type={showPassword ? "text" : "password"}
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: "20px" }}
                        />
                        {error && (
                            <FormHelperText
                                error
                                style={{ marginBottom: "10px", textAlign: "center" }}
                            >
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ padding: "10px 0", marginBottom: "10px" }}
                        >
                            Enviar
                        </Button>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link
                                    href="/login"
                                    variant="body2"
                                    style={{ color: blue[600] }}
                                >
                                    Já tem uma conta? Entre aqui
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </div>
    );
};

const styles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
        width: "100%",
    },
    message: {
        marginBottom: "20px",
        textAlign: "left",
        width: "100%",
    },
    logoBox: {
        marginBottom: "20px",
        position: "absolute",
        left: 0,
        top: 0,
    },
    logo: {
        width: "150px",
    },
};

export default RecoveryPassword;
