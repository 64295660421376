import * as React from 'react'
import { Box } from '@mui/material'

const styles = {
  container: {
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    marginBottom: '10px',
    padding: '20px',
  }
}

interface Props {
  children: React.ReactNode
}

const InnerContainer: React.FC<Props> = ({ children }) => {
  return <Box sx={styles.container}>{children}</Box>
}

export default InnerContainer
