import { Dispatch, SetStateAction, createContext } from 'react';

type Props = {
  selectedEvent: string,
  setSelectedEvent: Dispatch<SetStateAction<string>>
}

const SelectedEventContext = createContext<Props>({
  selectedEvent: '',
  setSelectedEvent: () => ''
});

export default SelectedEventContext;
