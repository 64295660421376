import React from 'react'
import {
  Box,
  Modal,
  Stack,
  Typography
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './DeleteTicketTypeModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'
import { TicketTypeType } from '../types'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: () => void
  details: TicketTypeType
}

const DeleteTicketTypeModal: React.FC<Props> = ({ showModal, setShowModal, submit, details }) => {

  const handleClose = (): void => {
    setShowModal(false)
  }
  const handleSubmit = (): void => {
    submit()
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Excluir Tipo de Ingresso</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
              '& .MuiAutocomplete-root': { m: 1, width: '100%' },
            }}
          >
            <Typography style={styles.attention}>Atenção!</Typography>
            <Typography style={styles.question}>{`Você realmente deseja excluir o tipo de ingresso "${details.name}"?`}</Typography>
            <Typography style={styles.warning}>Esta ação é irreversível</Typography>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 5 }}
          >
            <Button onClick={handleSubmit}>Excluir</Button>
            <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteTicketTypeModal

