import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: '#F5F5F5', // Um cinza claro para o fundo padrão
      paper: '#FFFFFF',
    },
    primary: {
      main: '#3A6EA5', // Azul Suave
      contrastText: '#FFFFFF', // Texto branco para contraste com o azul
    },
    secondary: {
      main: '#419BE0',
      contrastText: '#FFFFFF', // Texto branco para contraste com o rosa
    },
    error: {
      main: '#FF0000',
      dark: '#8B0000',
      light: '#FF6666',
    },
    success: {
      main: '#4CAF50', // Verde para mensagens de sucesso
      dark: '#388E3C',
      light: '#81C784',
    },
    warning: {
      main: '#FF9800', // Laranja para alertas e avisos
      dark: '#F57C00',
      light: '#FFB74D',
    },
    info: {
      main: '#2196F3', // Azul para informações gerais
      dark: '#1976D2',
      light: '#64B5F6',
    },
    // Adicione outras cores e propriedades conforme necessário
  },
  typography: {
    fontFamily: [
      'Open Sans', // Adiciona Open Sans como a primeira opção
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '2.5rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
    },
    // Adicione outras configurações de tipografia conforme necessário
  },
  // Você pode adicionar outras personalizações globais aqui
});

export default theme;
