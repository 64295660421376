import theme from "src/themes/theme";

export const styles = {
    details: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingBottom: 2
    },
    label: {
        color: theme.palette.primary.dark,
        fontWeight: "500",
        marginRight: 1,
        fontSize: 15,
    },
    stack: {
        paddingTop: 1,
        width: 400,
        marginBottom: 2,
    },
    val: {
        color: theme.palette.grey[700],
        fontWeight: "500",
        marginRight: 1,
    },
    icons: {
        fontSize: 50,
    },
    textField: {
        // marginBottom: '20px',
        '& label.Mui-focused': {
            color: theme.palette.secondary.main
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            backgroundColor: theme.palette.background.paper,
            '& fieldset': {
                borderWidth: '2px', // Altera a largura da borda
                borderColor: theme.palette.grey[400], // Cor padrão da borda
            },
            '&.Mui-focused fieldset': {
                borderRadius: '5px',
                borderColor: theme.palette.secondary.main
            },
            '& input': {
                height: '35px', // Ajuste a altura conforme necessário
                padding: '4px 8px', // Ajuste o padding conforme necessário
            },
        }
    }
};
