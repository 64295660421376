import React from 'react'
import {
  Box,
  Typography,
  Modal,
  Stack,
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './DeleteAttendeeModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'
import { AttendeeType } from '../../types'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: () => void
  selectedAttendee: AttendeeType
}

const DeleteAttendeeModal: React.FC<Props> = ({ selectedAttendee, showModal, setShowModal, submit }) => {
  const handleClose = (): void => { setShowModal(false) }
  const handleSubmit = (): void => {
    submit()
  }

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Deletar Participante</Header>
        </Box>

        <Box sx={styles.innerContainer}>
          <Typography>Você tem certeza que deseja deletar o participante "{selectedAttendee.firstName}"?</Typography>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 5 }}
          >
            <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
            <Button onClick={handleSubmit}>Deletar</Button>
          </Stack>
        </Box>

      </Box>
    </Modal>
  )
}

export default DeleteAttendeeModal
