import theme from 'src/themes/theme'

const styles = {
  viewModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
  },
  innerContainer: {
    paddingLeft: 4,
    paddingRight: 4,
    paddingBottom: 2,
    paddingTop: 4,
  },
  headerContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    p: '10px',
    paddingLeft: 4
  },
  label: {
    fontSize: 16,
    paddingLeft: 1,
    // fontFamily: 'OpenSans-Bold'
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '2px',
      '&.Mui-focused fieldset': {
        borderRadius: '2px',
        borderColor: theme.palette.secondary.main
      }
    }
  },

  ticket: { alignSelf: 'flex-start' },
  row: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start',
  },
  priceContainer: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'red',
    alignItems: 'center'
  }
}
export default styles
