import React, { useState, useMemo } from 'react'
import {
  Box,
  Modal,
  Stack,
  TextField,
  Typography
} from '@mui/material'
import 'react-datepicker/dist/react-datepicker.css'
import './CancelTicketModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: (data: any) => void
  selectedTicketId: string
}

const CancelTicketModal: React.FC<Props> = ({ showModal, setShowModal, submit, selectedTicketId }) => {
  const [cancellationReason, setCancellationReason] = useState<string>('')

  const isDisabled = useMemo(() => {
    const disable = (
      !cancellationReason
    )
    return disable
  }, [cancellationReason])

  const handleClose = (): void => {
    setShowModal(false)
  }
  const handleSubmit = (): void => {
    const data = {
      cancellationReason,
      isCancelled: true,
      ticketId: selectedTicketId
    }
    submit(data)
  }

  const handleChange = (setter: (value: any) => void) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setter(event.target.value);
    };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Cancelar Ingresso</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '100%' },
              '& .MuiAutocomplete-root': { m: 1, width: '100%' },
            }}
          >
            <Typography style={styles.attention}>Atenção!</Typography>
            <Typography style={styles.question}>Você realmente deseja cancelar o ingresso?</Typography>
            <Typography style={styles.warning}>Esta ação é irreversível</Typography>
            <Box sx={styles.row}>
              <TextField
                sx={styles.textField}
                id="anyId"
                label="Motivo do Cancelamento"
                variant="standard"
                placeholder='Ex. Pagamento não concluído, Confirmação de presença não realizada..'
                value={cancellationReason}
                onChange={handleChange(setCancellationReason)}
                inputProps={{
                  maxLength: 500
                }}
              />
            </Box>
          </Box>

          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 5 }}
          >
            <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
            <Button onClick={handleSubmit} isDisable={isDisabled} bgColor={theme.palette.error.dark}>Cancelar</Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  )
}

export default CancelTicketModal

