import React, { useState, useEffect, useContext, useCallback } from 'react'
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TableFooter,
  TablePagination,
  InputBase,
  Divider,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  Tooltip,
  Typography,
  Link
} from '@mui/material'
import { enqueueSnackbar } from 'notistack';
import IconButton from '@mui/material/IconButton'
import { type EventType } from './types'

import {
  Search as SearchIcon,
  Menu as MenuIcon,
  Feed as FeedIcon,
  DeleteForever as DeleteForeverIcon,
  EditCalendar as EditCalendarIcon,
  TaskAlt as TaskAltIcon,
  Unpublished as UnpublishedIcon,
  Report as ReportIcon,
  Restore as RestoreIcon,
} from '@mui/icons-material';

import Header from 'src/components/Header'
import Container from 'src/components/Container'
import InnerContainer from 'src/components/InnerContainer'
import Button from 'src/components/Button'
import CreateEventModal from './components/CreateEventModal/CreateEventModal'
import AxiosClient from 'src/clients/axios.client'
import NoFoundData from 'src/components/NoFoundData'
import styles from './styles'
import { CreateEvents, UpdateEvent } from 'src/clients/types'
import ContextWrapper from 'src/context/context/wrapper'
import { format } from 'date-fns';
import UpdateEventModal from './components/UpdateModal/UpdateModal'
import CancelEventModal from './components/CancelModal/CancelModal'
import Sidebar from 'src/components/Sidebar'
import theme from 'src/themes/theme';
import { isEventExpired } from 'src/utils/isEventExpired';
import { EventWeddingModel } from 'src/data/models';
import RestoreEventModal from './components/RestoreModal/RestoreModal';

const actions = [
  { icon: <FeedIcon />, name: 'Ver Mais' },
  { icon: <EditCalendarIcon />, name: 'Editar' },
  { icon: <DeleteForeverIcon />, name: 'Cancelar' },
  { icon: <RestoreIcon />, name: 'Restaurar' },
];

const Events: React.FC = () => {
  const { setLoading } = useContext(ContextWrapper['loading']());
  const { setSelectedEvent } = useContext(ContextWrapper['selectedEvent']());
  const { setActiveMenu } = useContext(ContextWrapper['activeMenu']());
  const { currentUser } = useContext(ContextWrapper['currentUser']());
  const [list, setList] = useState<{ rows: EventType[], count: number }>({ rows: [], count: 0 })
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [selectedEventId, setSelectEventId] = useState<string>('')
  const [cancellationReason, setCancellationReason] = useState<string>('')
  const [eventName, setEventName] = useState<string>('')
  const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false)
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false)
  const [showRestoreModal, setShowRestoreModal] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(5)
  const [loadingList, setLoadingList] = useState<boolean>(true)

  const getEvents = useCallback(async (): Promise<void> => {
    try {
      if ((eventName.length > 0 && eventName.length < 4)) {
        return;
      }
      const coordId = localStorage.getItem('id');
      if (!coordId) return;
      const params = {
        page: +page,
        pageSize: +rowsPerPage,
        coordId
      } as any
      if (eventName) {
        params.title = eventName
      }
      setLoadingList(true)
      const httpResponse = await AxiosClient.listEventsByCoordinator(params)
      setLoadingList(false)
      setList(httpResponse.data)

    } catch (error) {
      setLoadingList(false)
    }
  }, [page, rowsPerPage, eventName])

  const handleChangePage = (e: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitCreate = async (data: CreateEvents) => {
    try {
      setLoading(true)
      const httpResponse = await AxiosClient.createEvent(data)
      setLoading(false)
      if (httpResponse.status === 200) {
        getEvents()
        setShowCreateModal(false)
        enqueueSnackbar('Sucesso', { variant: 'success' })
      } else {
        enqueueSnackbar('Erro ao criar.', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar('Erro ao criar.', { variant: 'error' })
      setLoading(false)
    }
  }

  const submitUpdate = async (data: UpdateEvent & { weddingDetails?: Partial<EventWeddingModel> }) => {
    try {
      setLoading(true)
      const httpResponse = await AxiosClient.updateEvent(data, selectedEventId)
      setLoading(false)
      if (httpResponse.status === 200) {
        getEvents()
        setShowUpdateModal(false)
        enqueueSnackbar('Sucesso', { variant: 'success' })
      } else {
        enqueueSnackbar('Erro ao atualizar.', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar('Erro ao atualizar.', { variant: 'error' })
      setLoading(false)
    }
  }

  const submitCancel = async (data: UpdateEvent) => {
    try {
      setLoading(true)
      const params = {
        id: selectedEventId,
        isCancelled: true,
        cancellationReason,
      }
      const httpResponse = await AxiosClient.cancelEvent(params)
      setLoading(false)
      if (httpResponse.status === 200) {
        getEvents()
        setShowCancelModal(false)
        enqueueSnackbar('Sucesso', { variant: 'success' })
      } else {
        enqueueSnackbar('Erro ao cancelar!', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar('Erro ao cancelar!', { variant: 'error' })
      setLoading(false)
    }
  }

  const submitRestore = async () => {
    try {
      setLoading(true)
      const httpResponse = await AxiosClient.restoreEvent(selectedEventId)
      setLoading(false)
      if (httpResponse.status === 200) {
        getEvents()
        setShowRestoreModal(false)
        enqueueSnackbar('Sucesso', { variant: 'success' })
      } else {
        enqueueSnackbar('Erro ao restaurar!', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar('Erro ao restaurar!', { variant: 'error' })
      setLoading(false)
    }
  }

  const resendConfirmationToken = async () => {
    try {
      if (!currentUser?.email) return;
      setLoading(true)
      const httpResponse = await AxiosClient.resendConfirmationUserToken(currentUser?.email)
      setLoading(false)
      if (httpResponse.status === 200) {
        enqueueSnackbar('Sucesso', { variant: 'success' })
      } else {
        enqueueSnackbar('Erro ao enviar email!', { variant: 'error' })
      }
    } catch (error) {
      enqueueSnackbar('Erro ao enviar email!', { variant: 'error' })
      setLoading(false)
    }
  }

  useEffect(() => {
    getEvents()
  }, [getEvents])

  const handleShowCreateEventModal = (): void => {
    setShowCreateModal(true)
  }

  const handleActions = (actionName: string, eventId: string) => {
    setSelectEventId(eventId)
    setSelectedEvent(eventId)

    if (actionName === 'Editar') {
      setShowUpdateModal(true)
    }
    if (actionName === 'Cancelar') {
      setShowCancelModal(true)
    }
    if (actionName === 'Restaurar') {
      setShowRestoreModal(true)
    }
    if (actionName === 'Ver Mais') {
      setActiveMenu('Detalhes do Evento')
    }
  }

  const formatDate = (date: string | Date) => format(new Date(date), 'dd/MM/yyyy HH:mm');

  return (
    <Sidebar>
      <Container>
        <InnerContainer>
          <Header>Eventos</Header>
        </InnerContainer>
        {!currentUser?.isConfirmed && (
          <Box sx={styles.warningContainer}>
            <ReportIcon style={styles.warningIcon} />
            <Box sx={styles.infoWarningContainer}>
              <Box sx={styles.containerHeader}>
                <Header color={theme.palette.grey[50]}>Atenção!</Header>
                <Typography sx={styles.warning}>Seu email ainda não foi confirmado.</Typography>
              </Box>
              <Typography sx={styles.warningDetails}>Por favor, acesse seu email e siga as instruções enviadas.
                Caso seu link de confirmação tenha expirado,
                <Link href="#" onClick={resendConfirmationToken}>clique aqui</Link>
                que reenviaremos outro email.
              </Typography>
            </Box>
          </Box>
        )}
        <InnerContainer>
          <Stack direction="row" spacing={0} justifyContent="space-between" paddingBottom={5}>
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
              onSubmit={(e) => e.preventDefault()}
            >
              <IconButton sx={{ p: '10px' }} aria-label="menu" disabled>
                <MenuIcon />
              </IconButton>
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                onChange={(e) => setEventName(e?.target.value)}
                placeholder="Pesquisar evento..."
                inputProps={{ 'aria-label': 'pesquisar evento' }}
              />
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                aria-label="directions"
                onClick={() => getEvents()}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button onClick={handleShowCreateEventModal}>Criar Evento</Button>

          </Stack>
          {loadingList &&
            <Box sx={styles.loaderContainer}>
              <CircularProgress color="secondary" sx={styles.loading} />
            </Box>
          }
          {list.count ?
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Nome</TableCell>
                      <TableCell>Data</TableCell>
                      <Tooltip title='Exigir apresentação do convite na entrada do evento'>
                        <TableCell>Conv. Obrigatório</TableCell>
                      </Tooltip>
                      <Tooltip title='Exigir confirmação de presença'>
                        <TableCell>Conf. Presença</TableCell>
                      </Tooltip>
                      <TableCell>Nº Convites</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Mais</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {list.rows.map((event) => (
                      <TableRow key={event.id}>
                        <TableCell>{event.title}</TableCell>
                        <TableCell>{formatDate(event.startAt)}</TableCell>
                        <TableCell>{event.isTicketed ? 'Sim' : 'Não'}</TableCell>
                        <TableCell>{event.isRSVPRequired ? 'Sim' : 'Não'}</TableCell>
                        <TableCell>
                          {event.maxTicketQuantity >= 0 ? event.maxTicketQuantity : '-'}
                        </TableCell>
                        <TableCell>
                          <Tooltip title={event.isCancelled ? 'Evento Cancelado' : isEventExpired(event.endAt) ? 'Evento Expirado' : 'Evento Confirmado'}>
                            {event.isCancelled ? <UnpublishedIcon color='error' /> : <TaskAltIcon color={isEventExpired(event.endAt) ? 'warning' : 'success'} />}
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Box sx={{
                            width: '0px',
                          }}>
                            <SpeedDial
                              ariaLabel="SpeedDial basic example"
                              direction='left'
                              sx={styles.speedDial}
                              icon={<SpeedDialIcon />}
                            >
                              {actions.map((action, index) => {
                                if (event.isCancelled && action.name === 'Cancelar') return null
                                if (event.isCancelled && action.name === 'Editar') return null
                                if (!event.isCancelled && action.name === 'Restaurar') return null
                                return <SpeedDialAction
                                  key={index}
                                  icon={action.icon}
                                  tooltipTitle={action.name}
                                  onClick={(e) => handleActions(action.name, event.id)}
                                />
                              })}
                            </SpeedDial>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TableContainer component={Paper} >
                <Table sx={styles.table}>
                  <TableFooter sx={styles.tableFooter}>
                    <TableRow>
                      <TablePagination
                        sx={styles.tablePagination}
                        rowsPerPageOptions={[5, 10, 25]}
                        count={list.count}
                        rowsPerPage={+rowsPerPage}
                        page={+page}
                        SelectProps={{
                          inputProps: {
                            'aria-label': 'Rows per page',
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
            :
            <NoFoundData />
          }
        </InnerContainer>
        {showCreateModal && (
          <CreateEventModal
            showModal={showCreateModal}
            setShowModal={setShowCreateModal}
            submit={submitCreate}
          />
        )}
        {showUpdateModal &&
          <UpdateEventModal
            showModal={showUpdateModal}
            setShowModal={setShowUpdateModal}
            eventId={selectedEventId}
            submit={submitUpdate}
          />
        }
        {showCancelModal &&
          <CancelEventModal
            showModal={showCancelModal}
            setShowModal={setShowCancelModal}
            eventId={selectedEventId}
            setCancellationReason={setCancellationReason}
            cancellationReason={cancellationReason}
            submit={submitCancel}
          />
        }
        {showRestoreModal && (
          <RestoreEventModal
            showModal={showRestoreModal}
            setShowModal={setShowRestoreModal}
            eventId={selectedEventId}
            submit={submitRestore}
          />
        )}
      </Container>
    </Sidebar>
  )
}

export default Events
