import theme from "src/themes/theme";

export const styles = {
    label: {
        color: theme.palette.primary.dark,
        fontWeight: '500',
        marginRight: 1,
        fontSize: 15
    },
    val: {
        color: theme.palette.grey[700],
        fontWeight: '500',
        marginRight: 1,
    },
    headerTitle: {
        fontSize: 25,
        fontWeight: '500',
        paddingLeft: 2,
        color: theme.palette.primary.main,
        alignSelf: 'center',
    },
    details: {
        fontSize: 18,
        fontWeight: 'bold',
        paddingBottom: 2
    },
    icon: {
        color: theme.palette.primary
    },
    stack: {
        paddingTop: 1
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 4
    },
    loading: {
        alignText: 'center'
    },
    icons: {
        fontSize: 50
    },
    backBtn: {
        color: theme.palette.grey[700],
        fontSize: 25,
        fontWeight: '600',
        textTransform: 'none',
        padding: 0
    },
    error: {
        color: theme.palette.error.main,
        fontStyle: 'italic'
    }
}