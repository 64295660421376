import React, { useState, useEffect, useMemo } from 'react'
import {
  Box,
  Typography,
  Modal,
  Stack,
  CircularProgress,
  IconButton,
  Tooltip,
  Button as Btn,
} from '@mui/material'
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import './EventScheduleModal.css'

import Button from 'src/components/Button'
import Header from 'src/components/Header'
import styles from './styles'
import theme from 'src/themes/theme'
import AxiosClient from 'src/clients/axios.client'
import { TabPanelProps } from './types';
import { EventModel } from 'src/data/models';
import { CustomInputField } from 'src/components/TextField';

interface Props {
  showModal: boolean
  setShowModal: (val: boolean) => void
  submit: (data: any) => void
  loading: boolean
  setLoading: (data: boolean) => void
  eventId: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const EventScheduleModal: React.FC<Props> = ({ loading, setLoading, showModal, setShowModal, submit, eventId }) => {
  const [inputSponsorsFields, setInputSponsorsFields] = useState([''])
  const [inputSpeakersFields, setInputSpeakersFields] = useState([''])
  const [inputAgendaFields, setInputAgendaFields] = useState([{
    time: '', activity: ''
  }])
  const [tab, setTab] = React.useState(0);
  const [event, setEvent] = useState<EventModel>({
    budget: 0,
    userId: "",
    cancellationReason: "",
    createdAt: "",
    description: "",
    internalNotes: "",
    dressCode: "",
    endAt: new Date(),
    startAt: new Date(),
    eventType: "",
    id: "",
    isCancelled: false,
    isRSVPRequired: true,
    isTicketed: false,
    location: "",
    registrationDeadline: new Date(),
    agenda: [],
    speakers: [""],
    sponsors: [""],
    theme: "",
    maxTicketQuantity: 0,
    title: "",
    updatedAt: "",
    maxAgeForFreeAdmission: 0,
    isPrivate: false,
    ticketsCreated: 0,
  })

  type AgendaType = { time: string, activity: string }

  const isAgendaBtnDisabled = useMemo(() => {
    function checkArrayValues(array: AgendaType[]) {
      return array.some(item => !item.time || !item.activity);
    }
    return checkArrayValues(inputAgendaFields);
  }, [inputAgendaFields]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTab(newValue);
  };

  const handleAddSponsorsInput = () => {
    setInputSponsorsFields([...inputSponsorsFields, '']);
  };

  const handleAddSpeakersInput = () => {
    setInputSpeakersFields([...inputSpeakersFields, '']);
  };

  const handleAddAgendaInput = () => {
    setInputAgendaFields([...inputAgendaFields, { time: '', activity: '' }]);
  };

  const handleRemoveSponsorsInput = (index: number) => {
    const updatedInputs = [...inputSponsorsFields];
    updatedInputs.splice(index, 1);
    setInputSponsorsFields(updatedInputs);
  };

  const handleRemoveSpeakersInput = (index: number) => {
    const updatedInputs = [...inputSpeakersFields];
    updatedInputs.splice(index, 1);
    setInputSpeakersFields(updatedInputs);
  };

  const handleRemoveAgendaInput = (index: number) => {
    const updatedInputs = [...inputAgendaFields];
    updatedInputs.splice(index, 1);
    setInputAgendaFields(updatedInputs);
  };

  const handleClose = (): void => { setShowModal(false) }

  const handleSponsorSubmit = (): void => {
    const sponsors = inputSponsorsFields.filter(item => item)
    const data = { sponsors }
    submit(data)
  }

  const handleSpeakersSubmit = (): void => {
    const speakers = inputSpeakersFields.filter(item => item)
    const data = { speakers: speakers }
    submit(data)
  }

  const handleAgendaSubmit = (): void => {
    const data = { agenda: inputAgendaFields }
    submit(data)
  }

  const handleInputChange = (date: any, index: number) => {
    const updatedInputs = [...inputAgendaFields];
    updatedInputs[index].time = date;
    setInputAgendaFields(updatedInputs);
  };

  const getEvent = async () => {
    await AxiosClient.getEventById({ eventId, setLoading, setEvent })
  }

  useEffect(() => {
    if (event.sponsors) {
      setInputSponsorsFields(event.sponsors);
    }
    if (event.speakers) {
      setInputSpeakersFields(event.speakers);
    }
    const agendaParsed: any = []
    event.agenda.forEach((item) => {
      try {
        agendaParsed.push(JSON.parse(item))
      } catch (error) {
        console.error("Erro ao fazer o parse da string JSON:", error);
      }
    })
    setInputAgendaFields(agendaParsed);
  }, [event])

  useEffect(() => {
    getEvent()
  }, [])

  return (
    <Modal
      open={showModal}
    >
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Atualizar Programação do Evento</Header>
        </Box>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={tab} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Patrocinador" {...a11yProps(0)} />
              <Tab label="Cantor/Apresentador" {...a11yProps(1)} />
              <Tab label="Agenda" {...a11yProps(2)} />
            </Tabs>
          </Box>
          {loading ?
            <Box sx={styles.loaderContainer}>
              <CircularProgress color="secondary" sx={styles.loading} />
            </Box> :
            <>
              <CustomTabPanel value={tab} index={0}>
                <Box sx={styles.innerContainer}>
                  <Tooltip title="Adicionar Patrocinador">
                    <Btn aria-label="add"
                      sx={{ marginBottom: 1, alignSelf: 'flex-end', textAlign: 'end' }}
                      onClick={() => handleAddSponsorsInput()}>
                      <AddCircleIcon color="success" />
                      <Typography sx={styles.addSponsorTxt}>Patrocinador</Typography>
                    </Btn>
                  </Tooltip>
                  {inputSponsorsFields.map((input, index) => (
                    <Stack direction="row" spacing={0} key={index} sx={{ marginBottom: 1 }}>
                      <CustomInputField
                        label={`Patrocinador ${index + 1}`}
                        placeholder='Patrocinador'
                        value={input}
                        onChange={(e) => {
                          const updatedInputs = [...inputSponsorsFields];
                          updatedInputs[index] = e.target.value;
                          setInputSponsorsFields(updatedInputs);
                        }}
                        maxLength={60}
                      />
                      <Tooltip title="Remover Patrocinador">
                        <IconButton aria-label="remove" onClick={() => handleRemoveSponsorsInput(index)}>
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      {/* <Button onClick={() => handleRemoveSponsorsInput(index)}>Remover</Button> */}
                    </Stack>
                  ))}
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ paddingTop: 2 }}
                >
                  <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
                  <Button onClick={handleSponsorSubmit}>Salvar</Button>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={1}>
                <Box sx={styles.innerContainer}>
                  <Tooltip title="Adicionar Cantor/Apresentador">
                    <Btn aria-label="add"
                      sx={{ marginBottom: 1, alignSelf: 'flex-end', textAlign: 'end' }}
                      onClick={() => handleAddSpeakersInput()}>
                      <AddCircleIcon color="success" />
                      <Typography sx={styles.addSponsorTxt}>Cantor/Apresentador</Typography>
                    </Btn>
                  </Tooltip>
                  {inputSpeakersFields.map((input, index) => (
                    <Stack direction="row" spacing={0} key={index} sx={{ marginBottom: 1 }}>
                      <CustomInputField
                        label={`Cantor/Apresentador ${index + 1}`}
                        placeholder='Cantor/Apresentador'
                        value={input}
                        onChange={(e) => {
                          const updatedInputs = [...inputSpeakersFields];
                          updatedInputs[index] = e.target.value;
                          setInputSpeakersFields(updatedInputs);
                        }}
                        maxLength={60}
                      />
                      <Tooltip title="Remover Cantador/Apresentador">
                        <IconButton aria-label="remove" onClick={() => handleRemoveSpeakersInput(index)}>
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      {/* <Button onClick={() => handleRemoveSponsorsInput(index)}>Remover</Button> */}
                    </Stack>
                  ))}
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ paddingTop: 2 }}
                >
                  <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
                  <Button onClick={handleSpeakersSubmit}>Salvar</Button>
                </Stack>
              </CustomTabPanel>
              <CustomTabPanel value={tab} index={2}>
                <Box sx={styles.innerContainer}>
                  <Tooltip title="Adicionar Agenda">
                    <Btn aria-label="add"
                      sx={{ marginBottom: 1, alignSelf: 'flex-end', textAlign: 'end' }}
                      onClick={() => handleAddAgendaInput()}>
                      <AddCircleIcon color="success" />
                      <Typography sx={styles.addSponsorTxt}>Agenda</Typography>
                    </Btn>
                  </Tooltip>
                  {inputAgendaFields.map((input, index) => (
                    <Stack direction="row" key={index}>
                      <CustomInputField
                        label={`Apresentação ${index + 1}`}
                        placeholder='Apresentação'
                        value={input.activity}
                        onChange={(e) => {
                          const updatedInputs = [...inputAgendaFields];
                          updatedInputs[index].activity = e.target.value;
                          setInputAgendaFields(updatedInputs);
                        }}
                        maxLength={100}
                      />
                      {/* <TextField
                        sx={{ marginBottom: 1, marginLeft: 1, width: '20%' }}
                        label={`Horário`}
                        variant="outlined"
                        fullWidth
                        value={input.time}
                        onChange={(e) => handleInputChange(e, index)}
                      /> */}
                      <DatePicker
                        // style={{ marginBottom: 1, marginLeft: 1, width: '20%' }}
                        className={`custom-datepicker date-picker-schedule`}
                        selected={input.time ? new Date(input.time) : new Date()}
                        onChange={(date) => handleInputChange(date, index)}
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Time"
                        dateFormat="HH:mm"
                        timeFormat="HH:mm"
                      />
                      <Tooltip title="Remover Agenda">
                        <IconButton aria-label="remove" onClick={() => handleRemoveAgendaInput(index)}>
                          <RemoveCircleIcon color="error" />
                        </IconButton>
                      </Tooltip>
                      {/* <Button onClick={() => handleRemoveSponsorsInput(index)}>Remover</Button> */}
                    </Stack>
                  ))}
                </Box>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="flex-end"
                  sx={{ paddingTop: 2 }}
                >
                  <Button variant='outlined' bgColor="transparent" onClick={handleClose}>Fechar</Button>
                  <Button onClick={handleAgendaSubmit} isDisable={isAgendaBtnDisabled}>Salvar</Button>
                </Stack>
              </CustomTabPanel>
            </>
          }
        </Box>
      </Box>
    </Modal>
  )
}

export default EventScheduleModal
