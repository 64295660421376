import React, {
    useState,
    ReactNode
} from 'react';

import ContextWrapper from '../context/wrapper';
import { AccountModel } from 'src/data/models';

type Props = {
    children: ReactNode;
};

const CurrentUserContext = ContextWrapper['currentUser']();

const CurrentUserProvider = ({ children }: Props) => {
    const [currentUser, setCurrentUser] = useState<AccountModel | undefined>(undefined);

    const value = { currentUser, setCurrentUser };
    return (
        <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>
    );
};

export default CurrentUserProvider;