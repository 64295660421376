import LoadingProvider from './loaderProvider';
import SelectedEventProvider from './selectedEventProvider';
import AuthProvider from './authProvider';
import ActiveMenuProvider from './activeMenuProvider';
import CurrentUserProvider from './currentUserProvider';

const ProviderWrapper = {
  loading: () => LoadingProvider,
  selectedEvent: () => SelectedEventProvider,
  isAuth: () => AuthProvider,
  activeMenu: () => ActiveMenuProvider,
  currentUser: () => CurrentUserProvider,
};

export default ProviderWrapper;
