import * as React from 'react'
import { styled } from '@mui/system'

const ContainerStyled = styled('div')({
  margin: 30
})

interface Props {
  children: React.ReactNode
}

const Container: React.FC<Props> = ({ children }) => {
  return <ContainerStyled>{children}</ContainerStyled>
}

export default Container
