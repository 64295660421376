export const handleError = (error: any): string => {
    try {
        if (typeof error.response?.data?.error === 'object') {
            const field = Object.keys(error.response?.data.error)[0]
            return error.response?.data.error[field][0]
        } else if (error.response?.data?.error) {
            return error.response?.data.error
        }
        return 'Falha de comunicação com o servidor.'
    } catch (error) {
        return 'Falha de comunicação com o servidor.'
    }
}