import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import CoordinatorConfirmation from "src/pages/CoordinatorConfirmation";
import { MyAccount } from "src/pages";
import Events from "src/pages/Events/Event";
import EventDetails from "src/pages/EventDetails/index";
import TicketTypes from "src/pages/EventDetails/components/TicketType/TicketTypes";
import { Attendees } from "src/pages/EventDetails/components/Attendees";
import { Tickets } from "src/pages/EventDetails/components/Tickets";
import { Login } from "src/pages/Authentication";
import { GenerateOwnTicket } from "src/pages/GenerateOwnTicket";
import ContextWrapper from "src/context/context/wrapper";
import { useContext } from "react";
import { SignUp } from "src/pages/Authentication";
import { ResetPassword } from "src/pages/Authentication/ResetPassword";
import { RecoveryPassword } from "src/pages/Authentication/RecoveryPassword";
import UserConfirmation from "src/pages/UserConfirmation/UserConfirmation";
import { ConfirmPresence } from "src/pages/ConfirmPresence";
import PrivacyPolicy from "src/pages/PrivacyPolicies/PrivacyPolicies";
import TermsOfService from "src/pages/TermsOfService/TermsOfService";
import GetInTouch from "src/pages/GetInTouch/GetInTouch";

function RootRouter() {
  const { isAuth } = useContext(ContextWrapper['isAuth']());
  return (
    <Router>
      <div>
        <Routes>
          {isAuth ? (
            <>
              <Route path="/my-account" element={<MyAccount />} />
              <Route path="/events" element={<Events />} />
              <Route path="/events/details" element={<EventDetails />} />
              <Route path="/ticket-types" element={<TicketTypes />} />
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/attendees" element={<Attendees />} />
              <Route path="*" element={<Navigate to="/events" />} />
            </>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/reset-password" element={<ResetPassword />} />
              <Route path="/recovery-password" element={<RecoveryPassword />} />
              <Route path="/coord/confirmation" element={<CoordinatorConfirmation />} />
              <Route path="/user/confirmation" element={<UserConfirmation />} />
              <Route path="/generate-ticket" element={<GenerateOwnTicket />} />
              <Route path="/confirm-presence" element={<ConfirmPresence />} />

              <Route path="/privacy-policies" element={<PrivacyPolicy />} />
              <Route path="/terms-of-service" element={<TermsOfService />} />
              <Route path="/get-in-touch" element={<GetInTouch />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Routes>
      </div>
    </Router>
  );
}

export default RootRouter;
