export const validateAndFormatCpf = (cpf: any) => {
    const cleanedCpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos

    if (cleanedCpf.length !== 11) {
        return { isValid: false, formattedCpf: cpf }; // CPF inválido por tamanho
    }

    let sum = 0;
    let remainder;

    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1+$/.test(cleanedCpf)) {
        return { isValid: false, formattedCpf: cpf }; // CPF inválido
    }

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(cleanedCpf.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if ((remainder === 10) || (remainder === 11)) {
        remainder = 0;
    }

    if (remainder !== parseInt(cleanedCpf.substring(9, 10))) {
        return { isValid: false, formattedCpf: cpf };
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(cleanedCpf.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if ((remainder === 10) || (remainder === 11)) {
        remainder = 0;
    }

    if (remainder !== parseInt(cleanedCpf.substring(10, 11))) {
        return { isValid: false, formattedCpf: cpf };
    }

    // CPF válido, formatar
    const formattedCpf = cleanedCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    return { isValid: true, formattedCpf };
};

