import { useContext, useEffect, useState } from 'react';
import { TextField, Button, Container, Link, Grid, IconButton, InputAdornment, FormHelperText, useMediaQuery, useTheme, Typography, Box } from '@mui/material';
import { blue } from '@mui/material/colors';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
// import Logo from 'src/assets/images/evento_sem_fila.png';
import LogoSVG from "src/assets/images/logo";
// import BackgroundImage from 'src/assets/images/bground.jpg';
import BackgroundImage from "src/assets/images/bg_image.jpg";
import AxiosClient from 'src/clients/axios.client';
import ContextWrapper from 'src/context/context/wrapper';
import { enqueueSnackbar } from 'notistack';
import { validatePassword } from 'src/utils/validatePassword';
import { handleError } from 'src/utils/handleError';
import { AccountModel } from 'src/data/models';

import GoogleLogo from 'src/assets/images/google.png';
type PasswordValidationKeys = keyof ReturnType<typeof validatePassword>;
type PasswordRule = {
    key: PasswordValidationKeys;
    text: string;
};
const SignUp = () => {
    const { setLoading } = useContext(ContextWrapper['loading']());
    const { setIsAuth } = useContext(ContextWrapper['isAuth']());
    const { setActiveMenu } = useContext(ContextWrapper['activeMenu']());
    const { setCurrentUser } = useContext(ContextWrapper['currentUser']());
    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isPasswordError, setIsPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleMouseDownPassword = (event: any) => {
        event.preventDefault();
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const passwordValidation = (): boolean => {
        let isFormInvalid = false;
        if (!password) {
            setIsPasswordError('O campo está vazio.')
            isFormInvalid = true;
            return isFormInvalid;
        }

        const passIsValid = validatePasswordRules(password);
        if (passIsValid) {
            setIsPasswordError(passIsValid.text);
            isFormInvalid = true;
            return isFormInvalid;
        }

        if (password !== confirmPassword) {
            setIsPasswordError('Os campos Nova Senha e Confirmar Senha não conferem.');
            isFormInvalid = true;
            return isFormInvalid;
        }
        return isFormInvalid;
    };

    const validatePasswordRules = (text: string) => {
        const rules: PasswordRule[] = [
            { key: 'minLength', text: 'Pelo menos 8 caracteres' },
            { key: 'hasUpperCase', text: 'Pelo menos uma letra maiúscula' },
            { key: 'hasLowerCase', text: 'Pelo menos uma letra minúscula' },
            { key: 'hasNumbers', text: 'Pelo menos um número' },
            { key: 'hasSpecialChar', text: 'Pelo menos um caractere especial' },
        ];

        const passValidation = validatePassword(text);

        // Agora 'key' é garantidamente uma chave de passValidation
        const isPasswordInvalid = rules.find(rule => !passValidation[rule.key]);

        return isPasswordInvalid;
    };

    const saveUserInLStorage = (user: AccountModel) => {
        localStorage.setItem('token', user.accessToken);
        localStorage.setItem('email', user.email);
        localStorage.setItem('firstName', user.firstName);
        localStorage.setItem('id', user.id);
        localStorage.setItem('role', user.role);
        localStorage.setItem('avatar', user?.avatar || '');
        localStorage.setItem('isAuth', 'true');
    };

    const handleGoogleLogin = async () => {
        window.location.href = `${process.env.REACT_APP_API}/api/auth/google`;
    };

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            setError('');
            if (passwordValidation()) {
                return;
            }
            const params = {
                email,
                password,
                firstName,
                role: 'user',
            };
            setLoading(true);
            const user = await AxiosClient.signup(params);
            setLoading(false);
            setCurrentUser(user.data);
            saveUserInLStorage(user.data);
            setIsAuth(true);
            setActiveMenu('Eventos');
            enqueueSnackbar('Conta criada com sucesso!', { variant: 'success' });
        } catch (error: any) {
            setLoading(false);
            setError(handleError(error));
            enqueueSnackbar('Falha ao criar conta', { variant: 'error' });
        }
    };

    useEffect(() => {
        setIsPasswordError('');
    }, [password, confirmPassword]);

    return (
        <div style={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            padding: isSmallScreen ? '0 10px' : '0', // Adiciona padding em dispositivos móveis
        }}>
            <Container component="main" maxWidth="xs" style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh'
            }}>
                <Box style={{
                    padding: '20px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    backgroundColor: 'rgba(255, 255, 255, 0.9)',
                    borderRadius: '8px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                }}>
                    <Box sx={styles.logoBox}>
                        <LogoSVG color="white" size={200} />
                    </Box>
                    <Typography sx={{
                        fontSize: 30,
                        fontWeight: "bold",
                        marginBottom: "10px",
                        textAlign: 'left',
                        width: '100%'
                    }}>Cadastrar</Typography>

                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Nome"
                            autoFocus
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            style={{ marginBottom: '10px' }}
                            inputProps={{ maxLength: 30 }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="E-mail"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            style={{ marginBottom: '10px' }}
                            inputProps={{ maxLength: 70 }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Senha"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={!!isPasswordError}
                            helperText={isPasswordError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: '10px' }}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirmar Senha"
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="current-password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            style={{ marginBottom: '20px' }}
                        />
                        {error && (
                            <FormHelperText error style={{ marginBottom: '10px', textAlign: 'center' }}>
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{
                                padding: '10px 0',
                                marginBottom: '10px',
                                background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`
                            }}
                        >
                            Cadastrar
                        </Button>
                        <Button
                            onClick={handleGoogleLogin}
                            fullWidth
                            variant="contained"
                            style={{
                                padding: "10px 0",
                                marginBottom: "20px",
                                backgroundColor: "#ffffff",
                                color: "#757575",
                                borderRadius: "4px",
                                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.25)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                textTransform: "none",
                                fontWeight: "bold",
                            }}
                        >
                            <img
                                src={GoogleLogo}
                                alt="Google logo"
                                style={{ width: "20px", marginRight: "10px" }}
                            />
                            Entrar com Google
                        </Button>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link href="/login" variant="body2" style={{ color: blue[600] }}>
                                    Já tem uma conta? Entre aqui
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </div>
    );
};

const styles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: 'left',
        width: '100%'
    },
    message: {
        marginBottom: "20px",
        textAlign: 'left',
        width: '100%'
    },
    logoBox: {
        marginBottom: "20px",
        position: 'absolute',
        left: 0,
        top: 0,
    },
    logo: {
        width: "150px",
    },
};

export default SignUp;
