import { useCallback, useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import { format } from "date-fns";
import {
    Box,
    Button,
    Checkbox,
    Container,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from "notistack";

import BackgroundImage from "src/assets/images/bg_image.jpg";
import styles from "./styles";
import AxiosClient from "src/clients/axios.client";
import ContextWrapper from "src/context/context/wrapper";
import successAnimation from "./success-animation.json";
import { AttendeeModel } from "src/data/models";
import theme from "src/themes/theme";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const ConfirmPresence: React.FC = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const attendeeCode = query.get("attendeeCode");
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const [attendee, setAttendee] = useState<AttendeeModel | undefined>(
        undefined
    );
    const [error, setError] = useState("");
    const [notes, setNotes] = useState<string | undefined>(undefined);
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [isChild, setIsChild] = useState(false);

    const formatDate = (date: string | Date) =>
        format(new Date(date), "dd/MM/yyyy HH:mm:ss");

    const handleBack = () => {
        navigate("/login");
    };

    const handleIsChild = (val: boolean) => {
        setIsChild(!!val);
    };

    const handleSubmit = async () => {
        try {
            setError("");
            if (!attendeeCode) {
                return;
            }

            setLoading(true);
            const params = {
                attendeeCode,
                internalNotes: notes,
                isChild
            };
            const httpResponse = await AxiosClient.confirmPresenceAttendee(params);
            if (httpResponse.status === 200) {
                enqueueSnackbar("Sua presença foi confirmada", { variant: "success" });
                setStatus("success");
            } else {
                enqueueSnackbar("Falha ao confirmar sua presença", {
                    variant: "error",
                });
            }
            setLoading(false);
        } catch (error) {
            enqueueSnackbar("Falha ao confirmar sua presença", { variant: "error" });
            setLoading(false);
        }
    };

    const getAttendee = useCallback(async () => {
        try {
            if (!attendeeCode) {
                return;
            }
            setLoading(true);
            const httpResponse = await AxiosClient.getAttendeeByCode({
                attendeeCode,
            });
            if (httpResponse.status === 200) {
                setAttendee(httpResponse.data);
            } else {
                enqueueSnackbar("Erro ao obter detalhes do participante", {
                    variant: "error",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [setLoading, attendeeCode]);

    useEffect(() => {
        getAttendee();
    }, [getAttendee]);

    useEffect(() => {
        if (!attendeeCode) {
            navigate("/login");
        }
    }, [navigate, attendeeCode]);

    return (
        <Box
            style={{
                minHeight: "100vh",
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "auto",
            }}
        >
            <Grid item xs={12} sm={6}>
                {status === undefined ? (
                    <Container component="main" maxWidth="xs">
                        <Paper
                            elevation={6}
                            style={{
                                padding: "40px 20px",
                                width: "100%",
                                maxWidth: "400px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.98)",
                            }}
                        >
                            <Typography sx={styles.title}>Confirmar presença</Typography>
                            <Typography sx={styles.message}>
                                {`Olá ${attendee?.firstName || ""
                                    }! Preencha os campos abaixo para 
                                confirmar sua presença no evento - ${attendee
                                        ?.eventDetails?.title}.`}
                            </Typography>
                            {attendee?.eventDetails?.startAt && (
                                <Typography sx={styles.details}>{`Início: ${formatDate(
                                    attendee?.eventDetails?.startAt
                                )}`}</Typography>
                            )}
                            {attendee?.eventDetails?.endAt && (
                                <Typography sx={styles.details}>{`Fim: ${formatDate(
                                    attendee?.eventDetails?.endAt
                                )}`}</Typography>
                            )}
                            {attendee?.eventDetails?.dressCode && (
                                <Typography
                                    sx={styles.details}
                                >{`Vestimenta: ${attendee?.eventDetails?.dressCode}`}</Typography>
                            )}
                            {attendee?.eventDetails?.description && (
                                <Typography sx={styles.details}>
                                    {attendee?.eventDetails?.description}
                                </Typography>
                            )}
                            {attendee?.eventDetails?.internalNotes && (
                                <Typography
                                    sx={styles.details}
                                >{`Atenção! ${attendee?.eventDetails?.internalNotes}`}</Typography>
                            )}
                            <Divider sx={{ width: '100%', marginTop: 2 }} />
                            <Box sx={{
                                paddingTop: 2,
                                width: '100%',

                            }}>
                                <Typography>Possui idade igual ou menor a {attendee?.eventDetails?.maxAgeForFreeAdmission || 5} anos?</Typography>
                                <Box>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={isChild}
                                                onChange={(e) => handleIsChild(!isChild)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{ fontSize: "14px", color: theme.palette.grey[700] }}
                                            >
                                                Sim
                                            </Typography>
                                        }
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={!isChild}
                                                onChange={(e) => handleIsChild(!isChild)}
                                            />
                                        }
                                        label={
                                            <Typography
                                                sx={{ fontSize: "14px", color: theme.palette.grey[700] }}
                                            >
                                                Não
                                            </Typography>
                                        }
                                    />
                                </Box>
                            </Box>

                            <TextField
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                label="Adicionar notas ao organizador..."
                                autoFocus
                                value={notes || ""}
                                onChange={(e) => setNotes(e.target.value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <SpeakerNotesIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    maxLength: 200,
                                }}
                                style={{ marginBottom: 1 }}
                            />
                            {error && (
                                <FormHelperText error style={{ textAlign: "center" }}>
                                    {error}
                                </FormHelperText>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ padding: "10px 0", marginTop: "20px" }}
                                disabled={!attendee}
                                onClick={handleSubmit}
                            >
                                Confirmar
                            </Button>
                        </Paper>
                    </Container>
                ) : (
                    <Container component="main" maxWidth="xs">
                        <Paper
                            elevation={6}
                            style={{
                                padding: "40px 20px",
                                width: "100%",
                                maxWidth: "400px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                backgroundColor: "rgba(255, 255, 255, 0.98)",
                            }}
                        >
                            <Lottie animationData={successAnimation} loop={true} />
                            <Typography sx={styles.successMessage}>
                                Sua presença foi confirmada com sucesso!
                            </Typography>
                            <Typography sx={styles.attention}>
                                Atenção! Atente-se ao horário do evento e caso seja necessário,
                                não esqueça de levar seu ingresso.
                            </Typography>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ padding: "10px 0", marginTop: "20px" }}
                                onClick={handleBack}
                            >
                                VOLTAR
                            </Button>
                        </Paper>
                    </Container>
                )}
            </Grid>
        </Box>
    );
};

export default ConfirmPresence;
