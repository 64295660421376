import React, {
    useState,
    ReactNode
} from 'react';

import ContextWrapper from '../context/wrapper';

type Props = {
    children: ReactNode;
};

const ActiveMenuContext = ContextWrapper['activeMenu']();

const ActiveMenuProvider = ({ children }: Props) => {
    const [activeMenu, setActiveMenu] = useState<string>('Eventos');

    const value = { activeMenu, setActiveMenu };
    return (
        <ActiveMenuContext.Provider value={value}>{children}</ActiveMenuContext.Provider>
    );
};

export default ActiveMenuProvider;