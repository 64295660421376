import { SnackbarProvider, closeSnackbar } from 'notistack';
import React from "react";

interface Props {
    children: React.ReactNode
}

const Toaster = ({ children }: Props) => {

    return (
        <SnackbarProvider
            maxSnack={7}
            action={(snackbarId) => (
                <button onClick={() => closeSnackbar(snackbarId)}>
                    Fechar
                </button>
            )}
        >
            {children}
        </SnackbarProvider>
    )
}

export default Toaster