import axios from 'axios'

const api = axios.create({
  baseURL: process.env.REACT_APP_API
})

// Adicione o interceptor para adicionar o token JWT
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers['x-access-token'] = token
  }
  return config
})

export default api
