import { useCallback, useContext, useEffect, useState } from "react";
import { format } from 'date-fns';
import Lottie from "lottie-react";
import { Box, Button, Container, FormHelperText, Grid, InputAdornment, Paper, TextField, Typography } from "@mui/material"
import EmailIcon from '@mui/icons-material/Email';
import Smartphone from '@mui/icons-material/Smartphone';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import { useLocation, useNavigate } from "react-router-dom";
import { enqueueSnackbar } from 'notistack';

import BackgroundImage from 'src/assets/images/bg_image.jpg';

import styles from "./styles";
import AxiosClient from "src/clients/axios.client";
import ContextWrapper from "src/context/context/wrapper";
import { TicketType } from "./types";
import { validateCPF } from "src/utils/validateCpf";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import { validatePhoneNumber } from "src/utils/validatePhoneNumber";
import successAnimation from "./success-animation.json";

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const GenerateOwnTicket: React.FC = () => {
    const query = useQuery();
    const navigate = useNavigate();
    const ticketTypeCode = query.get('ticketTypeCode');
    const { setLoading } = useContext(ContextWrapper['loading']());
    const [firstName, setFirstName] = useState('')
    const [nickName, setNickName] = useState('')
    const [ticketType, setTicketType] = useState<TicketType | undefined>(undefined)
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [cpf, setCpf] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState<string | undefined>(undefined);

    const triggerDownload = (blob: any, fileName: any) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    };

    const handleBack = () => {
        setStatus(undefined)
        setFirstName('')
        setNickName('')
        setEmail('')
        setError('')
        setCpf('')
        setPhoneNumber('')
        setNotes('')
    }

    const handleChangePhoneNumber = (event: { target: { value: any; }; }) => {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value);
        setPhoneNumber(formattedPhoneNumber);
    };

    const submitDownloadTicket = async (ticketId: string) => {
        setLoading(true)
        const params = { ticketId }
        AxiosClient.downloadTicket(params)
            .then((blob: any) => {
                triggerDownload(blob.data, `${ticketId}.pdf`)
            })
            .catch((err) => enqueueSnackbar('Falha ao baixar ingresso.', { variant: 'error' }))
        setLoading(false)
    }

    const handleSubmit = async () => {
        try {
            setError('')
            if (!ticketType) {
                return
            }
            if (cpf && !validateCPF(cpf)) {
                setError('Cpf inválido')
                return;
            }
            if (phoneNumber && !validatePhoneNumber(phoneNumber)) {
                setError('Número de telefone inválido')
                return;
            }

            setLoading(true)
            const data = {
                ticketTypeId: ticketType?.ticketTypeId,
                eventId: ticketType?.eventId,
                firstName,
                phone: phoneNumber || undefined,
                email: email || undefined,
                attendeeType: 'guest'
            }

            const httpResponse = await AxiosClient.createOwnTicket(data)
            if (httpResponse.status === 200) {
                submitDownloadTicket(httpResponse.data.ticketId)
                enqueueSnackbar('Ingresso gerado com sucesso', { variant: 'success' })
                setStatus('success')
            } else {
                enqueueSnackbar('Falha ao gerar ingresso', { variant: 'error' })
            }
            setLoading(false)

        } catch (error) {
            enqueueSnackbar('Falha ao gerar ingresso', { variant: 'error' })
            setLoading(false)
        }
    }

    const getTicketType = useCallback(async () => {
        try {
            if (!ticketTypeCode) {
                return;
            }
            setLoading(true)
            const httpResponse = await AxiosClient.getTicketTypeByCode(ticketTypeCode)
            if (httpResponse.status === 200) {
                setTicketType(httpResponse.data)
            } else {
                enqueueSnackbar('Erro ao obter detalhes do evento', { variant: 'error' })
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }, [setLoading, ticketTypeCode])

    useEffect(() => {
        getTicketType()
    }, [getTicketType])

    useEffect(() => {
        if (!ticketTypeCode) {
            navigate('/login');
        }
    }, [navigate, ticketTypeCode])

    return (
        <Box style={{
            minHeight: '100vh',
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'auto',
        }}>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} sm={6}>
                    <Container component="main" maxWidth="xs">
                        <Paper elevation={6} style={{
                            padding: '40px 20px',
                            width: '100%',
                            maxWidth: '400px',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 255, 255, 0.98)',
                        }}>
                            <Typography sx={styles.title}>Detalhes do Evento</Typography>
                            {ticketType ? (
                                <>
                                    <Typography sx={[styles.info, { marginTop: 1 }]}>{`Evento: ${ticketType?.event?.title}`}</Typography>
                                    <Typography sx={styles.info}>{`Início: ${ticketType?.event.startAt ? format(new Date(ticketType.event.startAt), 'dd/MM/yyyy HH:mm:ss') : null}`}</Typography>
                                    <Typography sx={styles.info}>{`Fim: ${ticketType?.event.endAt ? format(new Date(ticketType.event.endAt), 'dd/MM/yyyy HH:mm:ss') : null}`}</Typography>
                                    <Typography sx={styles.info}>{`Local: ${ticketType?.event.location}`}</Typography>
                                    <Typography sx={styles.info}>{`Descrição: ${ticketType?.event.description || '-'}`}</Typography>
                                    <Typography sx={styles.info}>{`Ingresso: ${ticketType?.name || '-'}`}</Typography>
                                </>
                            ) : <Typography sx={styles.info}>Evento inexistente ou expirado.</Typography>}
                        </Paper>
                    </Container>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {status === undefined ? (
                        <Container component="main" maxWidth="xs">
                            <Paper elevation={6} style={{
                                padding: '40px 20px',
                                width: '100%',
                                maxWidth: '400px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.98)',
                            }}>
                                {/* <Box sx={styles.logoBox}> */}
                                {/* <img src={Logo} alt="Logo" style={styles.logo} /> */}
                                {/* </Box>   */}
                                <Typography sx={styles.title}>Criar Ingresso</Typography>
                                <Typography sx={styles.message}>Olá! Preencha os campos abaixo para continuar</Typography>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    label="Name"
                                    autoComplete="username"
                                    autoFocus
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    inputProps={{
                                        maxLength: 70
                                    }}
                                    style={{ marginBottom: 1 }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Apelido"
                                    autoComplete="username"
                                    autoFocus
                                    value={nickName}
                                    onChange={(e) => setNickName(e.target.value)}
                                    inputProps={{
                                        maxLength: 70
                                    }}
                                    style={{ marginBottom: 1 }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="E-mail"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <EmailIcon color="primary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 70
                                    }}
                                    style={{ marginBottom: 1 }}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Celular"
                                    autoComplete="tel"
                                    autoFocus
                                    value={phoneNumber}
                                    onChange={handleChangePhoneNumber}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Smartphone color="primary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 70
                                    }}
                                    style={{ marginBottom: 1 }}
                                />
                                {/* <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                label="Cpf"
                                autoFocus
                                value={cpf}
                                onChange={handleChangeCpf}
                                placeholder="000.000.000-00"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <AssignmentIndIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                                inputProps={{
                                    maxLength: 70
                                }}
                                style={{ marginBottom: 1 }}
                            /> */}
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    label="Notas"
                                    autoFocus
                                    value={notes}
                                    onChange={(e) => setNotes(e.target.value)}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <SpeakerNotesIcon color="primary" />
                                            </InputAdornment>
                                        ),
                                    }}
                                    inputProps={{
                                        maxLength: 200
                                    }}
                                    style={{ marginBottom: 1 }}
                                />
                                {error && (
                                    <FormHelperText error style={{ textAlign: 'center' }}>
                                        {error}
                                    </FormHelperText>
                                )}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ padding: '10px 0', marginTop: '20px' }}
                                    disabled={!ticketType}
                                    onClick={handleSubmit}
                                >
                                    Gerar
                                </Button>
                            </Paper>
                        </Container>
                    ) : (
                        <Container component="main" maxWidth="xs">
                            <Paper elevation={6} style={{
                                padding: '40px 20px',
                                width: '100%',
                                maxWidth: '400px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.98)',
                            }}>
                                <Lottie animationData={successAnimation} loop={true} />
                                <Typography sx={styles.successMessage}>Ingresso gerado com sucesso!</Typography>
                                <Typography sx={styles.attention}>Atenção! Aconselhamos a armazenar seu ingresso em um lugar seguro pois o mesmo deverá ser apresentado na recepção no dia do evento.</Typography>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    style={{ padding: '10px 0', marginTop: '20px' }}
                                    onClick={handleBack}
                                >
                                    VOLTAR
                                </Button>
                            </Paper>
                        </Container>
                    )}

                </Grid>
            </Grid>
        </Box>
    )
}

export default GenerateOwnTicket;
