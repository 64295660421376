import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, TextField, Typography } from "@mui/material";

import InnerContainer from "src/components/InnerContainer";
import Button from "src/components/Button";
import { DisplaySettings as DisplaySettingsIcon } from "@mui/icons-material";

import { styles } from "./styles";
import { EventWeddingModel } from "src/data/models";
import AxiosClient from "src/clients/axios.client";
import { enqueueSnackbar } from "notistack";
import { CustomInputField } from "src/components/TextField";

type Props = {
    id: string;
    eventType: string;
};

export const Settings: React.FC<Props> = ({ id, eventType }) => {
    const [wedding, setWedding] = useState<EventWeddingModel | undefined>(
        undefined
    );
    const [, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleChange = (val: string, prop: keyof EventWeddingModel) => {
        setWedding((prevState) => {
            if (!prevState) {
                return;
            }

            return {
                ...prevState,
                [prop]: val,
            };
        });

        if (error) setError("");
    };

    const getEvent = useCallback(async () => {
        try {
            setLoading(true);
            const httpResponse = await AxiosClient.getWeddingById(id);
            if (httpResponse.status === 200) {
                setWedding(httpResponse.data);
            } else {
                enqueueSnackbar("Falha ao buscar dados.", {
                    variant: "error",
                });
            }
        } catch (error) {
            enqueueSnackbar("Falha ao buscar dados.", {
                variant: "error",
            });
            console.log("Error: ", error);
        } finally {
            setLoading(false);
        }
    }, [id]);

    const handleSubmit = async () => {
        try {
            if (!wedding) {
                return;
            }
            setLoading(true);
            const httpResponse = await AxiosClient.updateWeddingSettings(wedding);
            if (httpResponse.status === 200) {
                enqueueSnackbar("Sucesso", { variant: "success" });
            } else {
                enqueueSnackbar("Falha ao atualizar informações.", {
                    variant: "error",
                });
            }
        } catch (error) {
            enqueueSnackbar("Falha ao atualizar informações.", {
                variant: "error",
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getEvent();
    }, [getEvent]);

    return (
        <Box>
            <InnerContainer>
                <Stack direction="row" spacing={0}>
                    <Stack sx={{ paddingRight: 3, paddingLeft: 5 }}>
                        <DisplaySettingsIcon color="primary" sx={styles.icons} />
                    </Stack>
                    <Stack direction="column" spacing={0}>
                        <Typography sx={styles.details}>Configurações</Typography>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <CustomInputField
                                required
                                placeholder="Ex: Guilherme"
                                label="Noivo:"
                                value={wedding?.groomName || ""}
                                onChange={(e) => handleChange(e.target.value, "groomName")}
                                maxLength={100}
                            />
                        </Stack>
                        <Stack direction="row" spacing={0} sx={styles.stack}>
                            <CustomInputField
                                required
                                placeholder="Ex: Gabriela"
                                label="Noiva:"
                                value={wedding?.brideName || ""}
                                onChange={(e) => handleChange(e.target.value, "brideName")}
                                maxLength={100}
                            />
                        </Stack>
                        <Stack spacing={0} sx={styles.stack}>
                            <TextField
                                sx={styles.textField}
                                id="groomParents"
                                label="Nome dos pais do noivo:"
                                placeholder="Ex: João e Maria"
                                value={wedding?.groomParents}
                                onChange={(e) => handleChange(e.target.value, "groomParents")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 70,
                                }}
                            />
                        </Stack>
                        <Stack spacing={0} sx={styles.stack}>
                            <TextField
                                sx={styles.textField}
                                id="brideParents"
                                label="Nome dos pais da noiva:"
                                placeholder="Ex: João e Maria"
                                value={wedding?.brideParents}
                                onChange={(e) => handleChange(e.target.value, "brideParents")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 70,
                                }}
                            />
                        </Stack>
                        {/* <Stack spacing={0} sx={styles.stack}>
                            <TextField
                                sx={styles.textField}
                                id="rsvpMessageWhatsApp"
                                label="Confirmação por Whatsapp:"
                                placeholder="Digite aqui..."
                                multiline
                                maxRows={4}
                                value={wedding?.rsvpMessageWhatsApp}
                                onChange={(e) => handleChange(e.target.value, "rsvpMessageWhatsApp")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 70,
                                }}
                            />
                        </Stack> */}
                        {/* <Stack spacing={0} sx={styles.stack}>
                            <TextField
                                sx={styles.textField}
                                id="rsvpMessageEmail"
                                label="Mensagem de confirmação Email:"
                                placeholder="Digite aqui..."
                                multiline
                                maxRows={4}
                                value={wedding?.rsvpMessageEmail}
                                onChange={(e) => handleChange(e.target.value, "rsvpMessageEmail")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    maxLength: 70,
                                }}
                            />
                        </Stack> */}
                        <Stack spacing={0} sx={styles.stack}>
                            <Button onClick={handleSubmit}>Salvar Alterações</Button>
                        </Stack>
                    </Stack>
                </Stack>
            </InnerContainer>
        </Box>
    );
};
