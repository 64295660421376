import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Avatar, Typography, Box, IconButton, Tooltip } from '@mui/material';
import { blueGrey } from '@mui/material/colors';
import EventIcon from '@mui/icons-material/Event';
import MenuIcon from '@mui/icons-material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

import DefaultAvatar from 'src/assets/images/default-avatar.png';
import { useNavigate } from 'react-router-dom';
import ContextWrapper from 'src/context/context/wrapper';
import theme from 'src/themes/theme';

type PropsType = {
    children: React.ReactNode
}
const Sidebar = ({ children }: PropsType) => {
    const { setIsAuth } = useContext(ContextWrapper['isAuth']());
    const { activeMenu, setActiveMenu } = useContext(ContextWrapper['activeMenu']());
    const { setSelectedEvent } = useContext(ContextWrapper['selectedEvent']());
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const drawerWidth = 240;
    const closedDrawerWidth = 80;

    const userEmail = localStorage.getItem('email');
    const userName = localStorage.getItem('firstName');
    const avatar = localStorage.getItem('avatar');

    const onLogout = useCallback(() => {
        console.log('onLogout')
        localStorage.removeItem('isAuth');
        setIsAuth(false)
        navigate('/login');
        localStorage.removeItem('token');
        localStorage.removeItem('email');
        localStorage.removeItem('id');
        localStorage.removeItem('name');
        localStorage.removeItem('role');
        localStorage.removeItem('avatar');
    }, [navigate, setIsAuth]);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleClick = (title: string) => {
        setSelectedEvent('')
        setActiveMenu(title); // Atualiza o estado do menu ativo
    }

    const getIconColor = (title: string) => {
        if (title === 'Eventos' && activeMenu === 'Detalhes do Evento') return theme.palette.primary.main
        return (title === activeMenu) && (title !== 'Sair') ? theme.palette.primary.main : theme.palette.grey[600];
    };

    const getBgColor = (title: string) => {
        if (title === 'Eventos' && activeMenu === 'Detalhes do Evento') return blueGrey[50]
        return (title === activeMenu) && (title !== 'Sair') ? blueGrey[50] : 'inherit'
    };

    useEffect(() => {
        switch (activeMenu) {
            case 'Participantes':
                navigate('/attendees');
                break;
            case 'Eventos':
                navigate('/events');
                break;
            case 'Detalhes do Evento':
                navigate('/events/details');
                break;
            case 'Minha Conta':
                navigate('/my-account');
                break;
            case 'Sair':
                onLogout();
                break;
            default:
        }
    }, [activeMenu, navigate, onLogout]); // Dependências

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer
                variant={open ? "temporary" : "permanent"}
                open={open}
                onClose={toggleDrawer}
                ModalProps={{
                    keepMounted: true, // Melhor desempenho em dispositivos móveis.
                }}
                sx={{
                    width: open ? drawerWidth : closedDrawerWidth,
                    '& .MuiDrawer-paper': {
                        width: open ? drawerWidth : closedDrawerWidth,
                        boxSizing: 'border-box',
                        backgroundColor: theme.palette.grey[100],
                        boxShadow: '4px 0 6px -2px rgba(0,0,0,0.2)'
                    },
                }}
            >
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '20px',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.grey[50],
                    minHeight: open ? '64px' : '30px',
                }}>
                    <Tooltip title={open ? "Fechar" : "Abrir"}>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                                position: 'absolute',
                                top: '15px',
                                left: open ? '15px' : null,
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    {open && <>
                        <Avatar
                            src={avatar || DefaultAvatar}
                            sx={{
                                width: 80,
                                height: 80,
                                marginBottom: '10px',
                                border: '3px solid',
                                borderColor: theme.palette.grey[50]
                            }}
                        />
                        <Typography variant="h6">{userName}</Typography>
                        <Typography variant="body2">{userEmail}</Typography>
                    </>}
                </Box>
                {open && <Divider />}
                <List sx={{ padding: 0 }}>
                    {['Eventos', 'Minha Conta', 'Sair'].map((text, index) => (
                        <ListItem
                            button
                            key={text}
                            sx={{
                                backgroundColor: getBgColor(text),
                                padding: '20px',
                                justifyContent: 'center',
                                display: 'flex',
                            }}
                            onClick={() => handleClick(text)}>
                            <ListItemIcon sx={{ minWidth: '0px !important' }}>
                                {(index === 0) &&
                                    <Tooltip title='Eventos'>
                                        <EventIcon sx={{ color: getIconColor(text) }} />
                                    </Tooltip>
                                }
                                {index === 1 &&
                                    <Tooltip title="Minha Conta">
                                        <ManageAccountsIcon sx={{ color: getIconColor("Minha Conta") }} />
                                    </Tooltip>
                                }
                                {index === 2 &&
                                    <Tooltip title="Sair">
                                        <ExitToAppIcon sx={{ color: theme.palette.grey[600] }} />
                                    </Tooltip>
                                }
                            </ListItemIcon>
                            {open && <ListItemText primary={text} sx={{ paddingLeft: 2 }} />}
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 1, marginLeft: 0 }}>
                {children}
            </Box>
        </Box>
    );
};

export default Sidebar;
