const styles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
        width: "100%",
    },
    info: {
        marginBottom: 1,
        alignSelf: 'flex-start',
        fontWeight: '500'
    },
    message: {
        alignSelf: 'flex-start',
        marginTop: 2,
        marginBottom: 2,
    },
    logoBox: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 200,
    },
    logo: {
        width: '60%',
        marginBottom: '20px',
    },
    successMessage: {
        fontWeight: 'bold',
        letterSpacing: 0.3,
    },
    attention: {
        textAlign: 'center',
        fontSize: 14,
    }
}
export default styles;