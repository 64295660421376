import React, { useState, useMemo, useEffect, ChangeEvent } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Modal,
  Stack,
  TextField,
  Typography,
  Tooltip,
  MenuItem
} from "@mui/material";
import "react-datepicker/dist/react-datepicker.css";
import "./CreateAttendeeModal.css";
import {
  Help as HelpIcon,
} from "@mui/icons-material";

import Button from "src/components/Button";
import Header from "src/components/Header";
import styles from "./styles";
import theme from "src/themes/theme";
import { validateAndFormatCpf } from "src/utils/validateAndFormatCpf";
import { phoneNumberMask } from "src/utils/phoneNumberMask";
import { validatePhoneNumber } from "src/utils/validatePhoneNumber";
import { keepOnlyNumbers } from "src/utils/keepOnlyNumbers";
import { AttendeeModel, EventModel } from "src/data/models";
import { enqueueSnackbar } from "notistack";
import AxiosClient from "src/clients/axios.client";

interface Props {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  submit: (data: any) => void;
  selectedEventId: string;
  event: EventModel;
}

const CreateAttendeeModal: React.FC<Props> = ({
  showModal,
  setShowModal,
  selectedEventId,
  submit,
  event,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState<string | null>(null);
  const [cpf, setCpf] = useState("");
  const [phone, setPhone] = useState("");
  const [attendeeType, setAttendeeType] = useState("guest");
  const [attendeeList, setAttendeeList] = useState<{ rows: AttendeeModel[], count: number }>({ rows: [], count: 0 });
  const [associatedToAttendeeId, setAssociatedToAttendeeId] = useState<string | undefined>(undefined);
  const [, setLoading] = useState(false)
  const [accessibilityNeeds, setAccessibilityNeeds] = useState("");
  const [internalNotes, setInternalNotes] = useState("");
  const [numberOfCompanions, setNumberOfCompanions] = useState(0);
  const [error, setError] = useState("");
  const [errorCpf, setErrorCpf] = useState("");
  const [isChild, setIsChild] = useState(false);
  const [sendNow, setSendNow] = useState(false);
  const [method, setMethod] = React.useState<
    "whatsapp" | "email" | undefined
  >(undefined);

  const isDisabled = useMemo(() => {
    const disable = name === "" || (cpf !== "" && !!errorCpf) || (sendNow && !method);
    return disable;
  }, [cpf, errorCpf, method, name, sendNow]);

  const handleClose = (): void => {
    setShowModal(false);
  };

  const handleAttendeeType = (val: string) => {
    setAttendeeType(val)
    if (val === 'guest') {
      setAssociatedToAttendeeId(undefined)
    }
  }

  const handleNumberOfCompanions = (val: string) => {
    setNumberOfCompanions(+val)
  }

  const handleSubmit = (): void => {
    const userEmail = localStorage.getItem("email");
    if (email && !emailValidate(email)) {
      setError('Email inválido')
      enqueueSnackbar(
        "Email inválido.",
        { variant: "error" }
      );
      return
    }
    if (phone && !validatePhoneNumber(phone)) {
      setError('Número de telefone inválido')
      enqueueSnackbar(
        "Número de telefone inválido.",
        { variant: "error" }
      );
      return;
    }
    if (attendeeType === 'companion' && !associatedToAttendeeId) {
      setError('Participante principal inválido.')
      enqueueSnackbar(
        "Selecione o campo 'Participante Principal'.",
        { variant: "error" }
      );
      return;
    }
    if (sendNow && !method) {
      enqueueSnackbar(
        "Escolha um método de envio.",
        { variant: "error" }
      );
      return;
    }
    const data = {
      eventId: selectedEventId,
      firstName: name,
      email,
      cpf,
      phone: phone ? `+55${keepOnlyNumbers(phone)}` : undefined,
      accessibilityNeeds,
      attendeeType,
      associatedToAttendeeId,
      registrationSource: userEmail,
      engagementHistory: [],
      internalNotes,
      isChild,
      sendNow,
      sendBy: method,
      numberOfCompanions
    };

    submit(data);
  };

  const handleChange =
    (setter: (value: string) => void) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
        if (error) setError("");
      };

  const handlePhone = (event: { target: { value: any } }) => {
    let value = event.target.value;
    setPhone(phoneNumberMask(value));
  };

  const handleChangeCpf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { isValid, formattedCpf } = validateAndFormatCpf(event.target.value);
    if (isValid || event.target.value.length < 14) {
      setCpf(formattedCpf); // Atualiza o estado com o CPF formatado ou parcial
      setErrorCpf("");
    } else {
      setErrorCpf("CPF inválido");
      setCpf(formattedCpf);
    }
  };

  const handleIsChild = (val: boolean) => {
    setIsChild(!!val);
  };

  const handleWhatsappMethod = () => {
    if (method === "whatsapp") {
      setMethod(undefined);
    } else {
      setMethod("whatsapp");
    }
  };

  const handleEmailMethod = () => {
    if (method === "email") {
      setMethod(undefined);
    } else {
      setMethod("email");
    }
  };

  const handleSendConfirmationNow = (val: boolean) => {
    setSendNow(val)
    if (!val) {
      setMethod(undefined)
    }
  }

  const emailValidate = (email: string) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const getAttendees = async () => {
    setLoading(true)
    const params = {
      eventId: selectedEventId,
      page: 0,
      pageSize: 1000,
      attendeeType: 'guest',
      hasAvailableCompanions: true,
    }
    AxiosClient.getAttendeeList(params)
      .then((resp) => {
        setAttendeeList(resp.data);
        setLoading(false);
      })
      .catch((err) => {
        enqueueSnackbar("Erro ao buscar participantes.", { variant: "error" });
        setLoading(false);
      });
  }

  useEffect(() => {
    if (!email && method === "email") {
      setMethod(undefined)
    }
    if (!phone && method === "whatsapp") {
      setMethod(undefined)
    }
  }, [phone, method, email])

  useEffect(() => {
    if (attendeeType === 'companion' && !attendeeList.count) {
      getAttendees()
    }
  }, [attendeeType])

  useEffect(() => {
    if (associatedToAttendeeId) {
      const attendee = attendeeList.rows.find((el) => el.attendeeId === associatedToAttendeeId)
      setPhone(attendee?.phone ? phoneNumberMask(attendee.phone.substring(3)) : '')
    }
  }, [associatedToAttendeeId, attendeeList.rows])

  return (
    <Modal open={showModal} onClose={handleClose}>
      <Box sx={styles.viewModal}>
        <Box sx={styles.headerContainer}>
          <Header color={theme.palette.grey[50]}>Cadastrar Participante</Header>
        </Box>
        <Box sx={styles.innerContainer}>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "100%" },
            }}
          >
            <Box sx={styles.row}>
              <TextField
                required
                fullWidth
                size="small"
                placeholder="Ex: João da Silva"
                label="Nome do Participante:"
                value={name}
                onChange={handleChange(setName)}
                inputProps={{
                  maxLength: 100,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                size="small"
                id="email"
                label="Email"
                placeholder="Ex: joao@gmail.com"
                value={email}
                onChange={handleChange(setEmail)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 70,
                }}
              />
            </Box>
            <Box sx={styles.row}>
              <TextField
                id="cpf"
                label="CPF"
                fullWidth
                size="small"
                placeholder="Ex: XXX.XXX.XXX-XX"
                value={cpf}
                onChange={handleChangeCpf}
                error={!!errorCpf}
                helperText={errorCpf}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                fullWidth
                size="small"
                id="celular"
                label="Celular"
                placeholder="Ex: (XX) XXXXX XXXX"
                value={phone}
                onChange={handlePhone}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Box>
            <Box sx={styles.row}>
              <TextField
                id="accessibilityNeeds"
                fullWidth
                size="small"
                label="Necessidades de Acessibilidade"
                placeholder="Ex: rampa"
                value={accessibilityNeeds}
                onChange={handleChange(setAccessibilityNeeds)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 500,
                }}
              />
              <TextField
                fullWidth
                size="small"
                id="notes"
                label="Notas"
                placeholder="Informações adicionais do participante"
                value={internalNotes}
                onChange={handleChange(setInternalNotes)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Box>
            <Box sx={styles.row}>
              <TextField
                fullWidth
                size="small"
                id="attendeeType"
                select
                required
                label="Tipo de Participante:"
                value={attendeeType}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  handleAttendeeType(event.target.value);
                }}
              >
                <MenuItem key="guest" value="guest">
                  Convidado
                </MenuItem>
                <MenuItem key="companion" value="companion">
                  Acompanhante
                </MenuItem>
              </TextField>

              {attendeeType === 'guest' ? (
                <TextField
                  id="numberOfCompanions"
                  select
                  required
                  fullWidth
                  size="small"
                  label="Quantidade de Acompanhantes:"
                  value={numberOfCompanions}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleNumberOfCompanions(event.target.value);
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                >
                  {[...Array(10).keys()].map((val) => <MenuItem key={val.toString()} value={val}>
                    {val}
                  </MenuItem>)}
                </TextField>
              ) : (
                <TextField
                  fullWidth
                  size="small"
                  id="attendeeType"
                  select
                  label="Participante Principal:"
                  value={associatedToAttendeeId}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    console.log('onChange', event.target.value)
                    setAssociatedToAttendeeId(event.target.value);
                  }}
                >
                  {attendeeList.rows.map((item) => (
                    <MenuItem key={item.attendeeId} value={item.attendeeId}>
                      {item.firstName} {item.lastName || item.nickName || ''}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </Box>
            {event.maxAgeForFreeAdmission > 0 && (
              <Box sx={[styles.row, { paddingTop: 1, paddingLeft: 1 }]}>
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={isChild}
                      onChange={(e) => handleIsChild(!isChild)}
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Typography
                        sx={{ fontSize: "14px", color: theme.palette.grey[700] }}
                      >
                        O participante possui idade igual ou menor a{" "}
                        {event.maxAgeForFreeAdmission} anos
                      </Typography>
                      <Tooltip title='Idade limite para entrada franca'>
                        <HelpIcon sx={styles.helpIcon} />
                      </Tooltip>
                    </Box>
                  }
                />
              </Box>
            )}
            {event.isRSVPRequired && (email || phone) && (
              <Box sx={[styles.column, { paddingTop: 0, paddingLeft: 1 }]}>
                <>
                  <Box sx={styles.row}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          size="small"
                          checked={sendNow}
                          onChange={(e) => handleSendConfirmationNow(!sendNow)}
                        />
                      }
                      label={
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: theme.palette.grey[700],
                            }}
                          >
                            Enviar agora o convite de confirmação de presença
                          </Typography>
                          <Tooltip title='O convite de confirmação poderá ser enviado ao participante em outro momento'>
                            <HelpIcon sx={styles.helpIcon} />
                          </Tooltip>
                        </Box>
                      }
                    />

                  </Box>
                  {sendNow && (
                    <Box sx={{ paddingLeft: 2 }}>
                      <Typography sx={{ fontSize: "14px", color: theme.palette.grey[700], }}>
                        Enviar por:
                      </Typography>

                      <FormControlLabel
                        sx={{ paddingLeft: 3 }}
                        control={
                          <Checkbox
                            checked={method === "email"}
                            onChange={handleEmailMethod}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ padding: 0 }}
                            size="small"
                            disabled={!email}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: theme.palette.grey[700],
                              paddingLeft: 1,
                            }}
                          >
                            Email
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        sx={{ paddingLeft: 1 }}
                        control={
                          <Checkbox
                            checked={method === "whatsapp"}
                            onChange={handleWhatsappMethod}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ padding: 0 }}
                            size="small"
                            disabled={!phone}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: theme.palette.grey[700],
                              paddingLeft: 1,
                            }}
                          >
                            Whatsapp
                          </Typography>
                        }
                      />

                    </Box>

                  )}
                </>
              </Box>
            )}
          </Box>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ paddingTop: 2 }}
          >
            <Button
              variant="outlined"
              bgColor="transparent"
              onClick={handleClose}
            >
              Fechar
            </Button>
            <Button onClick={handleSubmit} isDisable={isDisabled}>
              Cadastrar
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateAttendeeModal;
