import React, {
    useState,
    ReactNode
} from 'react';

import ContextWrapper from '../context/wrapper';

type Props = {
    children: ReactNode;
};

const SelectedEventContext = ContextWrapper['selectedEvent']();

const SelectedEventProvider = ({ children }: Props) => {
    const [selectedEvent, setSelectedEvent] = useState<string>('');

    const value = { selectedEvent, setSelectedEvent };
    return (
        <SelectedEventContext.Provider value={value}>{children}</SelectedEventContext.Provider>
    );
};

export default SelectedEventProvider;