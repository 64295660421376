export const phoneNumberMask = (value: string) => {
    // Remove tudo o que não é número
    value = value.replace(/\D/g, "");

    // Adiciona a máscara
    value = value.replace(/(\d{2})(\d{1,5})(\d{1,4})/, "($1) $2-$3");

    // Trunca o valor para se adequar ao tamanho máximo do telefone
    value = value.substring(0, 15);

    return value
}