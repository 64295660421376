import theme from 'src/themes/theme'

const styles = {
  viewModal: {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 800,
    bgcolor: 'background.paper',
    boxShadow: 24
    // p: 4,
  },
  innerContainer: {
    p: 4
  },
  headerContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
    p: '10px',
    paddingLeft: 4
  },
  row: {
    display: 'flex',
    direction: 'row',
    justifyContent: 'flex-start'
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  loading: {
    alignText: 'center'
  },
}
export default styles
