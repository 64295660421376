import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, FormHelperText, Grid, Paper, Box } from '@mui/material';
import { validatePassword } from 'src/utils/validatePassword';
import BackgroundImage from 'src/assets/images/bg_image.jpg';
import LogoSVG from 'src/assets/images/logo';

type Props = {
    handleConfirm: () => void
    password: string
    setPassword: (password: string) => void
    confirmPassword: string
    setConfirmPassword: (confirmPassword: string) => void
    username: string
    setUsername: (username: string) => void
    error: string
    setError: (val: string) => void
}
type PasswordValidationKeys = keyof ReturnType<typeof validatePassword>;
type PasswordRule = {
    key: PasswordValidationKeys;
    text: string;
};

function CoordinatorWithOutAccount({
    handleConfirm,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    username,
    setUsername,
    error,
    setError
}: Props) {
    const [passValidation, setPassValidation] = useState({
        minLength: false,
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumbers: false,
        hasSpecialChar: false,
    });

    const isButtonDisabled = !username || !password || !confirmPassword || password !== confirmPassword;

    const handleChange = (setter: (value: string) => void) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setter(event.target.value);
            if (error) setError('');
        };

    const handleChangePassword = (e: string) => {
        setPassword(e)
        setPassValidation(validatePassword(e));
    }

    const handleSubmit = () => {
        if (password !== confirmPassword) {
            setError('As senhas não coincidem');
            return;
        }
        if (!username) {
            setError('Nome de usuário vazio');
            return;
        }
        handleConfirm()
    };

    useEffect(() => {
        if (confirmPassword !== "" && password !== confirmPassword) {
            setError('As senhas não coincidem');
        }
    }, [password, confirmPassword, setError])

    const getUnmetPasswordRules = () => {
        const rules: PasswordRule[] = [
            { key: 'minLength', text: 'Pelo menos 8 caracteres' },
            { key: 'hasUpperCase', text: 'Pelo menos uma letra maiúscula' },
            { key: 'hasLowerCase', text: 'Pelo menos uma letra minúscula' },
            { key: 'hasNumbers', text: 'Pelo menos um número' },
            { key: 'hasSpecialChar', text: 'Pelo menos um caractere especial' },
        ];

        return rules.filter(rule => !passValidation[rule.key]).map(rule => <Typography key={rule.key}>{rule.text}</Typography>);
    };
    return (
        <div
            style={{
                height: '100vh',
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Box sx={styles.logoBox}>
                <LogoSVG color="white" size={200} />
            </Box>
            <Container maxWidth="sm">
                <Grid container spacing={2} justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
                    <Grid item xs={12} sm={10} md={8}>
                        <Paper elevation={6} style={{ padding: '30px' }}>
                            <Typography sx={styles.title}>
                                Confirmar Cadastro
                            </Typography>
                            <TextField
                                fullWidth
                                label="Nome de Usuário"
                                type="text"
                                value={username}
                                onChange={handleChange(setUsername)}
                                margin="normal"
                                variant="outlined"
                                inputProps={{
                                    maxLength: 30
                                }}
                            />
                            <TextField
                                fullWidth
                                label="Senha"
                                type="password"
                                value={password}
                                onChange={(event) => handleChangePassword(event.target.value)}
                                margin="normal"
                                variant="outlined"
                            />
                            <div style={{ marginBottom: '10px' }}>
                                {getUnmetPasswordRules()}
                            </div>
                            <TextField
                                fullWidth
                                label="Confirmar Senha"
                                type="password"
                                value={confirmPassword}
                                onChange={handleChange(setConfirmPassword)}
                                margin="normal"
                                variant="outlined"
                                error={!!error}
                            />
                            {error && (
                                <FormHelperText error style={{ marginBottom: '10px' }}>
                                    {error}
                                </FormHelperText>
                            )}
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSubmit}
                                disabled={isButtonDisabled}
                                fullWidth
                                style={{ marginTop: '20px' }}
                            >
                                Confirmar
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

const styles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
        width: "100%",
    },
    logoBox: {
        marginBottom: "20px",
        position: "absolute",
        left: 0,
        top: 0,
    },
}

export default CoordinatorWithOutAccount;
