import React, {
    useState,
    useEffect,
    useContext,
    useCallback,
} from "react";
import { format } from "date-fns";
import {
    Box,
    Button as MUIButton,
    Stack,
    Tooltip,
    Card,
    Typography,
} from "@mui/material";

import { enqueueSnackbar } from "notistack";
import {
    Refresh as RefreshIcon,
} from "@mui/icons-material";

import AxiosClient from "src/clients/axios.client";
import styles from "./styles";
import ContextWrapper from "src/context/context/wrapper";
import { PieChart } from "./components";
import { AttendeeModel } from "src/data/models";
import { phoneNumberMask } from "src/utils/phoneNumberMask";

const Dashboard: React.FC = () => {
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const { selectedEvent } = useContext(ContextWrapper["selectedEvent"]());
    const [data, setData] = useState<{
        totalTickets: string;
        uniqueAttendees: string;
        confirmedRevenue: number;
        grossSales: number;
        ticketsUsed: string;
        ticketsSent: string;
        ticketsCancelled: string;
        numberOfAttendees: string;
        numberOfConfirmedAttendees: string;
        numberOfPendingAttendees: string;
        numberOfCanceledAttendees: string;
        numberOfChildren: string;
        maxTicketQuantity: number;
        ticketsCreated: number;
        eventName: string;
    } | undefined>(undefined)
    const [attendeeList, setAttendeeList] = useState<Array<AttendeeModel>>([])

    const getDetails = useCallback(async (): Promise<void> => {
        try {
            if (!selectedEvent) {
                return;
            }
            setLoading(true);
            const httpResponse = await AxiosClient.dashboardDetails(selectedEvent)
            setLoading(false);

            if (httpResponse.status === 200) {
                setData(httpResponse.data)
            } else {
                enqueueSnackbar('Falha ao buscar dados.', { variant: 'error' })
            }

        } catch (error) {
            console.log('Exception // ', error)
            setLoading(false);
        }
    }, [selectedEvent, setLoading]);

    const getPresenceConfirmations = async () => {
        try {
            if (!selectedEvent) {
                return;
            }
            setLoading(true);
            const params = {
                page: 0,
                pageSize: 5,
                eventId: selectedEvent,
                registrationStatus: 'confirmed',
                orderBy: 'registrationDate',
            }
            const httpResponse = await AxiosClient.getAttendeeList(params)
            setLoading(false);

            if (httpResponse.status === 200) {
                setAttendeeList(httpResponse.data.rows)
            } else {
                enqueueSnackbar('Falha ao buscar dados.', { variant: 'error' })
            }
        } catch (error) {
            console.log('Exception // ', error)
            setLoading(false);
        }
    }

    useEffect(() => {
        if (selectedEvent) {
            getDetails();
            getPresenceConfirmations()
        }
    }, [getDetails, selectedEvent]);


    const formatDate = (date: string | Date) =>
        format(new Date(date), "dd/MM/yyyy HH:mm:ss");

    return (
        <Box>
            <Stack
                direction="row"
                spacing={0}
                justifyContent="flex-end"
                sx={{ marginBottom: 5 }}
            >
                <Stack direction="row" spacing={1} justifyContent="flex-end">
                    <Tooltip title="Atualizar">
                        <MUIButton variant="contained" onClick={() => getDetails()}>
                            <RefreshIcon />
                        </MUIButton>
                    </Tooltip>
                </Stack>
            </Stack>
            <Box sx={styles.cardsContainer}>
                {data && (
                    <Card
                        sx={styles.card}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography sx={styles.infoType}>
                                PARTICIPANTES
                            </Typography>
                            <Typography sx={[styles.infoType, { fontWeight: '400', paddingLeft: 1 }]}>
                                PRESENÇA
                            </Typography>
                        </Box>
                        <PieChart
                            labels={['Confirmados', 'Pendentes', 'Cancelados']}
                            values={[
                                +data?.numberOfConfirmedAttendees || 0,
                                +data?.numberOfPendingAttendees || 0,
                                +data?.numberOfCanceledAttendees || 0,
                            ]}
                            total={+data.numberOfAttendees}
                        />
                    </Card>

                )}
                {data?.numberOfAttendees && data.numberOfChildren && (
                    <Card
                        sx={styles.card}
                    >
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <Typography sx={styles.infoType}>
                                PARTICIPANTES
                            </Typography>
                            <Typography sx={[styles.infoType, { fontWeight: '400', paddingLeft: 1 }]}>
                                IDADE
                            </Typography>
                        </Box>
                        <PieChart
                            labels={['Adultos', 'Crianças']}
                            values={[
                                (+data.numberOfAttendees - +data.numberOfChildren) || 0,
                                +data?.numberOfChildren || 0,
                            ]}
                            total={+data.numberOfAttendees}
                        />
                    </Card>
                )}
                {
                    data?.ticketsCancelled && data.ticketsUsed && (
                        <Card
                            sx={styles.card}
                        >
                            <Typography sx={styles.infoType}>
                                INGRESSOS
                            </Typography>
                            <PieChart
                                labels={['Cancelados', 'Usados']}
                                values={[+data?.ticketsCancelled, +data?.ticketsUsed]}
                                total={+data.ticketsCreated}
                            />
                        </Card>
                    )
                }
            </Box>
            <Typography sx={styles.sectionTitle}>
                Últimas confirmações
            </Typography>
            {attendeeList.length ? attendeeList.map(item => (
                <Box key={item.attendeeId} sx={styles.attendeeList}>
                    <Typography sx={styles.item}>
                        {item.firstName}
                    </Typography>
                    <Typography sx={styles.item}>
                        {item.email || '-'}
                    </Typography>
                    <Typography sx={styles.item}>
                        {item.phone
                            ? phoneNumberMask(item.phone.replace("+55", ""))
                            : "-"}
                    </Typography>
                    <Typography sx={styles.item}>
                        {item.isChild ? 'Criança' : 'Adulto'}
                    </Typography>
                    <Typography sx={styles.item}>
                        {formatDate(item.registrationDate)}
                    </Typography>
                </Box>
            )) : <Typography sx={styles.item}>
                Não há participantes confirmados
            </Typography>}
        </Box>
    );
};

export default Dashboard;
