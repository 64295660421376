import theme from "src/themes/theme";

const styles = {
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: 4
    },
    loading: {
        alignText: 'center'
    },
    table: {
        width: '100%',
        maxWidth: '100%',
    },
    tableFooter: {
        zIndex: 1,
        display: 'flex',
        justifyContent: 'center',
    },
    tablePagination: {
        borderBottom: 'none',
    },
    warningContainer: {
        display: 'flex',
        backgroundColor: '#FF9800',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        marginBottom: '10px',
        padding: '20px',
        flexDirection: 'row',
        alignItems: 'center'
    },
    infoWarningContainer: {
        paddingLeft: 2,
        borderColorLeft: '#ccc',
        borderWidthLeft: 1,
    },
    warning: {
        color: theme.palette.grey[50]
    },
    warningDetails: {
        color: theme.palette.grey[50],
        fontSize: 13,
    },
    warningIcon: {
        color: 'white',
        fontSize: 70,
    },
    containerHeader: {
        flexDirection: 'row'
    },
    speedDial: {
        '& .MuiFab-primary': {
            alignSelf: 'center',
            '& .MuiSpeedDialIcon-icon': {
                fontSize: 18,
                color: 'white',
                alignSelf: 'center',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 20,
                padding: 1,
            }
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2),
        },
    }
}
export default styles;