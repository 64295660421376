import { Dispatch, SetStateAction, createContext } from 'react';
import { AccountModel } from 'src/data/models';

type Props = {
  currentUser: AccountModel | undefined,
  setCurrentUser: Dispatch<SetStateAction<AccountModel | undefined>>
}

const CurrentUserContext = createContext<Props>({
  currentUser: undefined,
  setCurrentUser: () => undefined
});

export default CurrentUserContext;
