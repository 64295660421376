import { useContext, useState } from "react";
import {
    TextField,
    Button,
    Container,
    Link,
    Grid,
    FormHelperText,
    useMediaQuery,
    useTheme,
    Box,
    Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { blue } from "@mui/material/colors";
import LogoSVG from "src/assets/images/logo";
import BackgroundImage from "src/assets/images/bg_image.jpg";
import AxiosClient from "src/clients/axios.client";
import ContextWrapper from "src/context/context/wrapper";

const ResetPassword = () => {
    const { setLoading } = useContext(ContextWrapper["loading"]());
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

    const handleSubmit = async (event: any) => {
        try {
            event.preventDefault();
            setLoading(true);
            await AxiosClient.resetPassword(email);
            setLoading(false);
            enqueueSnackbar(
                "O Token de recuperação de senha será enviado por email.",
                { variant: "success" }
            );
        } catch (error: any) {
            setLoading(false);
            setError(error.response?.data?.error || "Erro ao recuperar senha.");
            enqueueSnackbar("Tente mais tarde.", { variant: "error" });
        }
    };

    return (
        <div
            style={{
                height: "100vh",
                backgroundImage: `url(${BackgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: isSmallScreen ? "0 10px" : "0",
            }}
        >
            <Box sx={styles.logoBox}>
                <LogoSVG color="white" size={200} />
            </Box>
            {/* {!isSmallScreen && (
                <Box maxWidth="xs">
                    <Box
                        style={{
                            width: "60vw",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: '100vh',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            justifyContent: 'center',
                        }}
                    >
                        <Box sx={{ width: 500 }}>
                            <img src={Logo} alt="Logo" style={{ width: "100%" }} />
                        </Box>
                        <Typography
                            sx={{
                                fontSize: 20,
                                paddingTop: 4,
                                alignSelf: "center",
                                fontWeight: '500',
                                color: '#FFFFFF',
                            }}
                        >
                            Seu evento, sem complicações
                        </Typography>
                    </Box>
                </Box>
            )} */}
            <Container
                component="main"
                maxWidth="xs"
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100vh", // Centraliza verticalmente o Container
                }}
            >
                <Box
                    style={{
                        padding: "20px",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        borderRadius: "8px",
                        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 30,
                            fontWeight: "bold",
                            marginBottom: "10px",
                            textAlign: "left",
                            width: "100%",
                        }}
                    >
                        Recuperar Senha
                    </Typography>

                    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            label="E-mail"
                            autoComplete="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            inputProps={{
                                maxLength: 70,
                            }}
                            style={{ marginBottom: "10px" }}
                        />
                        {error && (
                            <FormHelperText
                                error
                                style={{ marginBottom: "10px", textAlign: "center" }}
                            >
                                {error}
                            </FormHelperText>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ padding: "10px 0", marginBottom: "10px" }}
                            disabled={!email}
                        >
                            Enviar
                        </Button>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Link
                                    href="/login"
                                    variant="body2"
                                    style={{ color: blue[600] }}
                                >
                                    Já tem uma conta? Entre aqui
                                </Link>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Container>
        </div>
    );
};

const styles = {
    title: {
        fontSize: 30,
        fontWeight: "bold",
        marginBottom: "10px",
        textAlign: "left",
        width: "100%",
    },
    message: {
        marginBottom: "20px",
        textAlign: "left",
        width: "100%",
    },
    logoBox: {
        marginBottom: "20px",
        position: "absolute",
        left: 0,
        top: 0,
    },
    logo: {
        width: "150px",
    },
};

export default ResetPassword;
